import { useContext } from "react";

import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "src/shared/GlobalProvider";
import { IndexUI } from "src/constants";

const OverlayLoading = () => {
  const { show } = useContext(GlobalContext);

  return (
    <Backdrop sx={{ color: "#fff", zIndex: IndexUI.POPUP }} open={show["loading"]}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default OverlayLoading;
