export enum LocalStorageKey {
  CART = "vac_cart_storage",
}

export const IndexUI = {
  POPUP: 999999,
  STICKY_ELEMENT: 99999,
  CART_PAGE_HEADER_STICKY: 1,
};

export const HeightPX = {
  SEARCH_BAR: "35px",
  NAV_BAR: "32px",
  MOBILE_HEADER: "82.5px",
  PRODUCT_NAVIGATION: "43px",
};

export const locales: Intl.LocalesArgument = "vi-VN";

export const LIMIT_PRODUCT_ITEM = 16;
