import { Stack, Box } from "@mui/material";
import CustomAccordion from "src/layouts/MainRightSection/CustomAccordion";
import Articles from "../Articles";
import CategoriesList from "src/components/CategoryList";

// const ProductsAllLink = styled(Link)`
//   text-decoration: none;
//   color: black;
//   display: flex;
//   align-items: center;
//   padding-bottom: 10px;

//   :hover {
//     text-decoration: underline;
//   }
// `;

// const ProductMenuDropdowns = () => {
//   const searchProducts = useSearchProducts();
//   const { menusData, isFetchingMenus } = useFetchMenus();
//   const leftMenuNestedData = getNestedMenuByKey("left-menu", menusData);

//   const handleClickCategory = useCallback(
//     (categoryId: string) => {
//       searchProducts({ collection: categoryId });
//     },
//     [searchProducts]
//   );

//   const categoriesData = useMemo(() => {
//     if (!leftMenuNestedData || !("sub_menus" in leftMenuNestedData)) {
//       return [];
//     }

//     return leftMenuNestedData.sub_menus;
//   }, [leftMenuNestedData]);

//   if (isFetchingMenus) {
//     return <CircularProgress />;
//   }

//   if (categoriesData.length === 0) {
//     return <></>;
//   }

//   return (
//     <>
//       {categoriesData.map(({ collection: { title }, sub_menus: subGroupMenus }, categoryIndex) => (
//         <CustomAccordion
//           key={`category-item-${categoryIndex}`}
//           title={title}
//           content={
//             <>
//               {subGroupMenus.map((subGroupMenu, index) => (
//                 <Box key={`category-children-item-${index}`} pb={1.5}>
//                   {subGroupMenu.sub_menus.map(
//                     ({ collection: { title: subMenuTitle, id: subMenuId } }, indexChild) => (
//                       <Box
//                         width="fit-content"
//                         sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
//                         onClick={() => handleClickCategory(subMenuId)}
//                         key={`category-name-${indexChild}`}>
//                         <Typography fontSize={13} lineHeight={1.1}>
//                           {subMenuTitle}
//                         </Typography>
//                       </Box>
//                     )
//                   )}
//                 </Box>
//               ))}
//             </>
//           }
//         />
//       ))}
//     </>
//   );
// };

const DefaultComponent = () => {
  return (
    <Stack sx={{ overflow: "auto", height: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2, px: 2.5, mb: 2 }}>
        <CustomAccordion title="SẢN PHẨM" link="/products" content={<CategoriesList />} />
        <CustomAccordion title="KHÁM PHÁ" link="/discovery" />
        <CustomAccordion title="EDITORIAL" link="/editorials" />
      </Box>
      <Articles />
    </Stack>
  );
};

export default DefaultComponent;
