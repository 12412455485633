import { Badge, Box, Stack, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import CartItemQuantityEditable from "src/components/CartItemQuantityEditable";
import { HeightPX } from "src/constants";
import CheckoutInfoFooter from "src/layouts/CartPageContent/CheckoutInfo/CheckoutInfoFooter";
import CheckoutInfoHeader from "src/layouts/CartPageContent/CheckoutInfo/CheckoutInfoHeader";
import { CART_HEADER_PX, SHIPPING_INFO_FOOTER_PX } from "src/layouts/CartPageContent/const";
import { itemsCartSelector } from "src/redux/features/CartSlice";
import { useAppSelector } from "src/redux/store";
import { OrderForm } from "src/types/forms";
import { convertStringCurrency, reduceUrlImage } from "src/utils/helper";

const CheckoutInfo = (orderFormMethod: UseFormReturn<OrderForm>) => {
  const carts = useAppSelector(itemsCartSelector);

  const tempPrice = carts.reduce(
    (total, curCart) => (total += curCart.product_price * curCart.quantity),
    0
  );

  return (
    <Stack flex={2} position={"relative"} height={`calc(100vh - ${HeightPX.NAV_BAR})`}>
      <CheckoutInfoHeader totalProduct={carts.length} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "auto",
          height: `calc(100vh - ${CART_HEADER_PX} - ${SHIPPING_INFO_FOOTER_PX} - 74px - 32px)`, // by order discount height, gap
          boxSizing: "border-box",
          p: 2,
        }}>
        {carts.map(
          ({ product_name, imageUrl, product_price, quantity, variant_id, stockQuantity }) => (
            <Box
              key={variant_id}
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
              <Stack direction={"row"} spacing={1}>
                <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                  <Badge badgeContent={quantity} color="primary">
                    <Box sx={{ width: "50px", height: "50px" }}>
                      <img
                        src={reduceUrlImage({ originUrl: imageUrl, width: 70 })}
                        width="100%"
                        height="100%"
                        alt="cart-item"
                      />
                    </Box>
                  </Badge>

                  <Stack gap={0.5}>
                    <Typography fontWeight="500">{product_name}</Typography>
                    <CartItemQuantityEditable
                      variant_id={variant_id}
                      quantity={quantity}
                      stockQuantity={stockQuantity}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontWeight="500">{convertStringCurrency(product_price)}</Typography>
              </Box>
            </Box>
          )
        )}
      </Box>

      <CheckoutInfoFooter tempPrice={tempPrice} orderFormMethod={orderFormMethod} />
    </Stack>
  );
};

export default CheckoutInfo;
