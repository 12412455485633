import { ReactNode, useState } from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { OrderForm } from "src/types/forms";
import { UseFormReturn } from "react-hook-form";
import { HeightPX, IndexUI } from "src/constants";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShippingInfoHeader from "src/layouts/CartPageContent/ShippingInfo/ShippingInfoHeader";
import ShippingInfoForm from "src/layouts/CartPageContent/ShippingInfo/ShippingInfoForm";
import ShippingInfoFooter from "src/layouts/CartPageContent/ShippingInfo/ShippingInfoFooter";
import { SHIPPING_INFO_FOOTER_PX } from "src/layouts/CartPageContent/const";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

type DeliveryInformationProps = {
  title: string;
  content: string;
  icon: ReactNode;
};

const DeliveryInformation = ({ title, content, icon }: DeliveryInformationProps) => (
  <>
    <Box mt={0.5} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {icon}
      <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
        {title}
      </Typography>
    </Box>

    <FormControlLabel
      sx={{ ml: 3 }}
      control={<Checkbox defaultChecked disabled />}
      label={
        <Typography variant="body1" sx={{ fontSize: 12 }}>
          {content}
        </Typography>
      }
    />
  </>
);

const OrderSuccessDialogContent = () => {
  const navigate = useNavigate();

  return (
    <Stack alignItems={"center"} spacing={1}>
      <Box py={2}>
        <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "50px" }} color="success" />
      </Box>
      <Typography variant="body1" textAlign={"center"}>
        Vạc sẽ liên hệ với bạn sau khi xác nhận đơn hàng. Để đơn hàng được xác nhận nhanh hơn, bạn
        có thể NHẮN TIN vào Fanpage của Vạc qua các kênh{" "}
        <a
          href="https://www.instagram.com/vac.closet"
          rel="noopener noreferrer"
          style={{ color: "black", textDecoration: "none", display: "inline-block" }}>
          <Typography variant="body2" fontWeight={500}>
            INSTAGRAM
          </Typography>
        </a>{" "}
        hoặc{" "}
        <a
          href="https://www.facebook.com/vac.closet"
          rel="noopener noreferrer"
          style={{ color: "black", textDecoration: "none", display: "inline-block" }}>
          <Typography variant="body2" fontWeight={500}>
            FACEBOOK
          </Typography>
        </a>{" "}
        nhé! Nếu đơn hàng được chốt sau 21:30, cửa hàng sẽ liên hệ lại bạn vào ngày tiếp theo!
      </Typography>
      <Box p={2}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
          sx={{ backgroundColor: "black", borderRadius: 0 }}>
          Tiếp tục mua sắm
        </Button>
      </Box>
    </Stack>
  );
};

const OrderFailedDialogContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack alignItems={"center"}>
      <Box py={3}>
        <InfoOutlinedIcon sx={{ fontSize: "50px" }} color="error" />
      </Box>
      <Typography variant="body1" textAlign={"center"}>
        Bạn không thể tạo đơn với với không có sản phẩm nào. Vui lòng thử lại, xin cảm ơn!
      </Typography>
      <Box p={2}>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ backgroundColor: "black", borderRadius: 0 }}>
          Đóng
        </Button>
      </Box>
    </Stack>
  );
};

const ShippingInfo = (orderFormMethod: UseFormReturn<OrderForm>) => {
  const { matchMobileViewMedium } = useCustomMediaQuery();
  const [dialogType, setDialogType] = useState<"hide" | "failed" | "success">("hide");

  const handleOpenDialog = (dialogType: "failed" | "success" = "success") => {
    setDialogType(dialogType);
  };

  const handleClose = () => {
    setDialogType("hide");
  };

  return (
    <Box flex={3} position={"relative"} height={`calc(100vh - ${HeightPX.NAV_BAR})`}>
      <ShippingInfoHeader />

      <Box
        px={2}
        sx={
          matchMobileViewMedium
            ? {}
            : {
                height: `calc(100vh - ${HeightPX.NAV_BAR} - ${SHIPPING_INFO_FOOTER_PX} - 111px)`,
                overflow: "auto",
              }
        }>
        <ShippingInfoForm orderFormMethod={orderFormMethod} />

        <DeliveryInformation
          title="Phương thức vận chuyển"
          content="Giao hàng tiết kiệm"
          icon={<LocalShippingIcon />}
        />

        <DeliveryInformation
          title="Phương thức thanh toán"
          content="Thanh toán khi giao hàng (COD)"
          icon={<PaymentIcon />}
        />
      </Box>

      <ShippingInfoFooter
        handleOpenDialog={handleOpenDialog}
        handleSubmit={orderFormMethod.handleSubmit}
      />

      <Dialog
        open={dialogType !== "hide"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: IndexUI.POPUP,
        }}>
        <DialogContent>
          {dialogType === "success" ? (
            <OrderSuccessDialogContent />
          ) : (
            <OrderFailedDialogContent onClose={handleClose} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ShippingInfo;
