import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import {
  closeDiscoveryDetailModal,
  totalSelectedDiscoveryDetails,
} from "src/redux/features/DiscoveryDetailSlice";
import { useAppSelector } from "src/redux/store";
import { MainContainer } from "src/shared/styles";

import CartDrawer from "./CartDrawer";
import DiscoveryDetailModal from "./DiscoveryDetailModal";
import RightSection from "./MainRightSection";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

const PageLayout = () => {
  const dispatch = useDispatch();
  const isOpenDiscoveryDetail = useAppSelector(totalSelectedDiscoveryDetails) > 0;
  const { matchMobileView } = useCustomMediaQuery();

  return (
    <Box sx={{ position: "relative" }}>
      <MainContainer sx={{ opacity: isOpenDiscoveryDetail ? 0.3 : 1 }}>
        {/* TODO: Using 'width' instead of 'flex-grow' because of 'Swiper'. SHOULD investigate more about this */}
        <Stack width={matchMobileView ? "100vw" : "80vw"}>
          <Outlet />
        </Stack>
        <Box width={"20vw"} display={matchMobileView ? "none" : "block"}>
          <RightSection />
        </Box>
      </MainContainer>

      {isOpenDiscoveryDetail && (
        <DiscoveryDetailModal closeModal={() => dispatch(closeDiscoveryDetailModal())} />
      )}

      <CartDrawer />
    </Box>
  );
};

export default PageLayout;
