import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useVoucher from "src/hooks/useVoucher";
import { getFormattedCoupon } from "src/shared/helpers/common";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { UseFormReturn } from "react-hook-form";
import { OrderForm } from "src/types/forms";
import { VoucherResponse } from "src/types/services";
import { IndexUI } from "src/constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ApplyVoucherFailedContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack alignItems={"center"}>
      <Box py={3}>
        <InfoOutlinedIcon sx={{ fontSize: "50px" }} color="error" />
      </Box>
      <Typography variant="body1" textAlign={"center"}>
        Khuyến mãi vừa nhập không thể sử dụng, mong bạn vui lòng sử dụng lại mã khác. Xin cảm ơn!
      </Typography>
      <Box p={2}>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ backgroundColor: "black", borderRadius: 0 }}>
          Đóng
        </Button>
      </Box>
    </Stack>
  );
};

const CheckoutInfoDiscount = ({ setValue }: Pick<UseFormReturn<OrderForm>, "setValue">) => {
  const [showDiscountTag, setShowDiscountTag] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>("");

  const onApplyVoucherSuccess = (dataResponse: VoucherResponse) => {
    setShowDiscountTag(true);

    if (dataResponse) {
      setValue("voucherField", dataResponse);
    }
  };
  const onApplyVoucherFailed = () => {
    setShowDialogVoucherFailed(true);
  };
  const { checkVoucher, isVoucherChecking, voucher } = useVoucher({
    code: discountCode,
    onSuccess: onApplyVoucherSuccess,
    onError: onApplyVoucherFailed,
  });

  const onDeleteVoucher = () => {
    setShowDiscountTag(false);
    setValue("voucherField", null);
  };

  const onApplyVoucher = () => {
    checkVoucher();
  };

  const [showDialogVoucherFailed, setShowDialogVoucherFailed] = useState(false);
  const handleCloseDialogVoucherFailed = () => {
    setShowDialogVoucherFailed(false);
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        borderTop: "0.5px solid black",
      }}>
      {showDiscountTag && voucher ? (
        <>
          <Typography>Khuyến mãi: </Typography>
          <Tooltip
            title={
              getFormattedCoupon({
                amountType: voucher.voucher_amount_type,
                amountValue: voucher.voucher_amount_value,
              }).expectedValue
            }>
            <Chip
              size="medium"
              variant="outlined"
              label={voucher.code}
              icon={<DiscountOutlinedIcon fontSize="inherit" />}
              onDelete={onDeleteVoucher}
              sx={{ marginLeft: 1 }}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <TextField
            size="small"
            placeholder="Mã giảm giá"
            label="Mã giảm giá"
            variant="outlined"
            disabled={isVoucherChecking}
            fullWidth
            onChange={(e) => setDiscountCode(e.target.value.trim())}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />
          <Button
            variant="contained"
            disabled={!discountCode || isVoucherChecking}
            sx={{
              backgroundColor: "black",
              borderRadius: "30px",
              textWrap: "nowrap",
            }}
            onClick={onApplyVoucher}
            {...(isVoucherChecking
              ? { endIcon: <CircularProgress size={12} color="inherit" /> }
              : {})}>
            Sử dụng
          </Button>
        </>
      )}

      <Dialog
        open={showDialogVoucherFailed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: IndexUI.POPUP,
        }}>
        <DialogContent>
          <ApplyVoucherFailedContent onClose={handleCloseDialogVoucherFailed} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CheckoutInfoDiscount;
