import { Box, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import NumericFormatInput from "src/components/common/NumericFormatInput";
import AutocompleteHF from "src/components/rhf/AutocompleteHF";
import TextFieldHF from "src/components/rhf/TextFieldHF";
import useFetchDistricts from "src/hooks/useFetchDistricts";
import useFetchProvinces from "src/hooks/useFetchProvinces";
import useFetchWards from "src/hooks/useFetchWards";
import { OrderForm } from "src/types/forms";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useEffect } from "react";

interface ShippingInfoFormProps {
  orderFormMethod: UseFormReturn<OrderForm>;
}
const ShippingInfoForm = ({ orderFormMethod }: ShippingInfoFormProps) => {
  const { control, resetField, watch, setValue } = orderFormMethod;

  const shipment = watch("shipment");

  const { provincesData } = useFetchProvinces();
  const { wardsData } = useFetchWards({ district_id: shipment?.district_id });
  const { districtsData } = useFetchDistricts({ province_id: shipment?.province_id });

  useEffect(() => {
    if (!shipment) return;
    if (shipment.province_id === "79") {
      setValue("shipment.shipmentPrices", 22000);
    } else if (shipment.province_id) {
      setValue("shipment.shipmentPrices", 26000);
    } else {
      setValue("shipment.shipmentPrices", 0);
    }
  }, [shipment, shipment?.province_id, setValue]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, my: 2 }}>
        <LocationCityIcon />
        <Typography fontWeight="600" variant="subtitle1">
          Thông tin giao hàng
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.2, mb: 2 }}>
        <Box display={"flex"} gap={1.2}>
          <TextFieldHF<OrderForm>
            control={control}
            name="customer.last_name"
            rules={{
              required: {
                value: true,
                message: "Họ không được để trống",
              },
            }}
            label="Họ"
            placeholder="Họ"
            required
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />

          <TextFieldHF<OrderForm>
            control={control}
            name="customer.first_name"
            rules={{
              required: {
                value: true,
                message: "Tên không được để trống",
              },
            }}
            label="Tên"
            placeholder="Tên"
            required
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />
        </Box>
        <TextFieldHF<OrderForm>
          control={control}
          name="customer.phone_number"
          rules={{
            required: {
              value: true,
              message: "Số điện thoại không được để trống",
            },
            pattern: {
              value: /([\\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/,
              message: "Số điện thoại không hợp lệ",
            },
          }}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: NumericFormatInput as any,
            style: {
              borderRadius: "30px",
            },
          }}
          inputProps={{
            thousandSeparator: false,
            allowLeadingZeros: true,
            maxLength: 10,
            size: 10,
          }}
          label="Số điện thoại"
          placeholder="Số điện thoại"
          required
        />

        <AutocompleteHF<OrderForm>
          formControlProps={{
            control,
            name: `shipment.province_id`,
            rules: {
              required: {
                value: true,
                message: "Vui lòng địa chỉ không được để trống",
              },
            },
          }}
          placeholder="Tỉnh/thành"
          label="Tỉnh/thành"
          options={provincesData.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          required
          onChangeAdditional={() => {
            resetField("shipment.district_id");
            resetField("shipment.ward_id");
          }}
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />

        <AutocompleteHF<OrderForm>
          formControlProps={{
            control,
            name: `shipment.district_id`,
            rules: {
              required: {
                value: true,
                message: "Vui lòng địa chỉ không được để trống",
              },
            },
          }}
          placeholder="Quận/huyện"
          label="Quận/huyện"
          options={districtsData.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          required
          disabled={!watch("shipment.province_id")}
          onChangeAdditional={() => {
            resetField("shipment.ward_id");
          }}
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />

        <AutocompleteHF<OrderForm>
          formControlProps={{
            control,
            name: `shipment.ward_id`,
            rules: {
              required: {
                value: true,
                message: "Vui lòng địa chỉ không được để trống",
              },
            },
          }}
          placeholder="Phường/xã"
          label="Phường/xã"
          options={wardsData.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          required
          disabled={!watch("shipment.province_id") || !watch("shipment.district_id")}
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />

        <TextFieldHF<OrderForm>
          control={control}
          name="customer.address"
          label="Số nhà, đường"
          placeholder="Số nhà, đường"
          required
          rules={{
            required: {
              value: true,
              message: "Vui lòng địa chỉ không được để trống",
            },
          }}
          onChangeAdditional={(e) => {
            if (e.target.value) {
              setValue("shipment.address", e.target.value);
            }
          }}
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />

        <TextFieldHF<OrderForm>
          control={control}
          name="note"
          label="Ghi chú"
          placeholder="Ghi chú"
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />
      </Box>
    </>
  );
};

export default ShippingInfoForm;
