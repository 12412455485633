import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "src/redux/store";

export interface DiscoveryDetailItem {
  id?: number | string;
  title: string;
  description: string;
  cols?: number;
  rows?: number;
  content: string;
}

interface DiscoveryState {
  items: DiscoveryDetailItem[];
}

const initialState: DiscoveryState = {
  items: [],
};

export const DiscoverySlice = createSlice({
  name: "discovery-detail",
  initialState,
  reducers: {
    openDiscoveryDetailModal: (state, action: PayloadAction<DiscoveryDetailItem>) => {
      state.items.push(action.payload);
    },
    closeDiscoveryDetailModal: (state) => {
      state.items = [];
    },
  },
});

export default DiscoverySlice.reducer;

const items = (state: RootState) => state.DiscoveryDetail.items;
export const totalSelectedDiscoveryDetails = createSelector([items], (items) => items.length);
export const discoveryItem = createSelector([items], (item) => item[0]);

export const { openDiscoveryDetailModal, closeDiscoveryDetailModal } = DiscoverySlice.actions;
