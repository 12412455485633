import Signatures from "src/components/Signatures";
import SpotlightSection from "src/components/SpotlightSection";

import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import ContactInfo from "src/components/ContactInfo";
import MobileHeader from "src/components/MobileHeader";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import HomePageInfo from "src/layouts/LandingPageContent/HomePageInfo";

const DefaultContent = () => (
  <>
    <HomePageInfo />

    <Signatures />

    <SpotlightSection />
  </>
);

const LandingPageContent = () => {
  const [currentSection, setCurrentSection] = useState(DefaultContent);
  const CurrentComponent = useMemo(() => currentSection, [currentSection]);

  const { matchMobileView } = useCustomMediaQuery();

  useEffect(() => {
    if (!matchMobileView) {
      setCurrentSection(DefaultContent);
    }
  }, [matchMobileView]);

  useEffect(() => {
    document.getElementById("landing-page-content")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [CurrentComponent]);

  return (
    <Box overflow={"auto"} id={"landing-page-content"}>
      <MobileHeader />

      {CurrentComponent}

      {matchMobileView ? (
        <ContactInfo setCurrentSection={setCurrentSection} defaultHomePage={DefaultContent} />
      ) : null}
    </Box>
  );
};
export default LandingPageContent;
