import { Route, Routes } from "react-router-dom";
import PageLayout from "src/layouts/PageLayout";
import CartPage from "src/pages/CartPage";
import DiscoveryPage from "src/pages/DiscoveryPage";
import EditorialDetailPage from "src/pages/EditorialDetailPage";
import EditorialsPage from "src/pages/EditorialsPage";
import LandingPage from "src/pages/LandingPage";
import NotFoundPage from "src/pages/NotFoundPage/NotFoundPage";
import ProductDetailPage from "src/pages/ProductDetailPage";
import ProductsPage from "src/pages/ProductsPage";
import ActionSnackbar from "src/shared/components/ActionSnackbar";
import OverlayLoading from "src/shared/components/OverlayLoading";
import GlobalProvider from "src/shared/GlobalProvider";

const RoutesChild = () => (
  <GlobalProvider>
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/:productId" element={<ProductDetailPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/editorials" element={<EditorialsPage />} />
        <Route path="/discovery" element={<DiscoveryPage />} />
      </Route>
      <Route path="/editorial-detail/:editorialId" element={<EditorialDetailPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>

    <ActionSnackbar />
    <OverlayLoading />
  </GlobalProvider>
);

export default RoutesChild;
