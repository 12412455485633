import { Grid, Skeleton, Stack } from "@mui/material";

const ExpandableProductListLoading = () => (
  <Grid container>
    {Array.from({ length: 4 }).map((_, index) => (
      <Grid item key={`product-item-skeleton-${index}`} xs={6} md={4} lg={3} p={1}>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"100%"} height={300} />
          <Skeleton variant="rounded" width={"100%"} height={70} />
        </Stack>
      </Grid>
    ))}
  </Grid>
);

export default ExpandableProductListLoading;
