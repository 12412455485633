import { fetchData, getApiHost } from "src/utils/service";

import { MenuDataResponse } from "src/types/services";
import { useQuery } from "@tanstack/react-query";

const useFetchMenus = () => {
  const { data, isFetching } = useQuery<MenuDataResponse>({
    staleTime: 1 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryKey: ["menus"],
    queryFn: () => fetchData({ url: `${getApiHost()}/menus` }),
  });

  return {
    menusData: data ? data.menu_items : [],
    isFetchingMenus: isFetching,
  };
};

export default useFetchMenus;
