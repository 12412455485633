import { locales } from "src/constants";
import { CouponAmountType } from "src/types/helpers";

export const parseArrayJsonLocalStorage = <T>(key?: string): T[] => {
  const keyString = typeof key === "string" ? key : "";

  const itemJson = localStorage.getItem(keyString);

  return JSON.parse(typeof itemJson === "string" ? itemJson : "[]") as T[];
};

export const getFormattedCoupon = ({
  amountType,
  amountValue,
}: {
  amountType: CouponAmountType;
  amountValue: string | number;
}) => {
  const transformByType = {
    PERCENTAGE: {
      typeLabel: "Tỷ lệ phần trăm",
      options: {
        style: "percent",
      },
      value: Number(amountValue) / 100,
    },
    FIXED_AMOUNT: {
      typeLabel: "Số tiền cố định",
      options: {
        style: "currency",
        currency: "VND",
      },
      value: Number(amountValue),
    },
    NONE: {
      typeLabel: "",
      value: 0,
    },
  } as Record<
    CouponAmountType,
    {
      typeLabel: string;
      options?: Intl.NumberFormatOptions;
      value: number;
    }
  >;
  const { typeLabel, options, value } = transformByType[amountType ?? "NONE"];

  return {
    typeLabel,
    expectedValue: value.toLocaleString(locales, options),
  };
};
