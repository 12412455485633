import { useContext, useMemo } from "react";
import { GlobalContext } from "src/shared/GlobalProvider";

const useShowSnackbar = () => {
  const { showSnackbar } = useContext(GlobalContext);

  return useMemo(() => showSnackbar, [showSnackbar]);
};

export default useShowSnackbar;
