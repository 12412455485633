import { Skeleton, Stack } from "@mui/material";

const ProductDetailLoading = () => (
  <Stack direction={"row"} p={1} spacing={1}>
    <Skeleton variant="rounded" width={"50%"} height={"90vh"} />
    <Stack width={"50%"} spacing={1}>
      <Skeleton variant="rounded" width={"100%"} height={"15vh"} />
      <Skeleton variant="rounded" width={"100%"} height={"50vh"} />
      <Skeleton variant="rounded" width={"100%"} height={"calc(25vh - 16px)"} />
    </Stack>
  </Stack>
);

export default ProductDetailLoading;
