import { useLocation } from "react-router";

/**
 * Generates a URL with query parameters.
 *
 * @param baseUrl - The base URL to generate the URL from.
 * @param queryParams - An object containing the query parameters to add to the URL.
 * @returns The generated URL with query parameters.
 */
export const generateUrlWithQueryParams = (
  baseUrl: string,
  queryParams: Record<string, string>
): string => {
  const url = new URL(baseUrl);
  Object.entries(queryParams).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.toString();
};

/**
 * A custom React hook that returns an object containing the query parameters from the current URL.
 *
 * @returns An object containing the query parameters from the current URL.
 */
export const useQueryParams = () => {
  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};
