import { OrderForm } from "src/types/forms";
import { VoucherResponse } from "src/types/services";

export const getVoucherPrice = ({
  totalProductsPrice,
  voucher,
}: {
  totalProductsPrice: number;
  voucher: VoucherResponse | null;
}) => {
  if (!totalProductsPrice || !voucher) return 0;

  if (voucher.voucher_amount_type === "PERCENTAGE") {
    return totalProductsPrice * (voucher.voucher_amount_value / 100);
  } else {
    return voucher.voucher_amount_value;
  }
};

export const getTotalProductsPrice = (orderItems: OrderForm["orderItems"]) => {
  const tempTotalProductPrice: number = orderItems.reduce(
    (totalPrices, orderItem) => (totalPrices += orderItem.quantity * orderItem.product_price),
    0
  );
  return tempTotalProductPrice;
};

export const isFreeShipping = (finalPrice: number) => {
  const freeShippingPrice = 500000;
  return finalPrice > freeShippingPrice;
};
