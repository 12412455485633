import { type ComponentProps } from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
`;

const FullWithImage = (props: ComponentProps<"img">) => <Image {...props} loading="lazy" />;

export default FullWithImage;
