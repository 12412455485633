import axios, { AxiosRequestConfig, AxiosResponse, type AxiosStatic } from "axios";
import { type PathGet, type PathPost } from "src/types/services";

export const getApiHost = () => `${process.env.REACT_APP_API_END_POINT}`;

export const getData = async ({
  path,
  config = undefined,
  slug = "",
}: {
  path: PathGet;
  config?: Parameters<AxiosStatic["get"]>[1];
  slug?: string;
  requiredAuth?: boolean;
}) => {
  const pathname = slug !== "" ? `${path}/${slug}` : `${path}`;

  return await axios
    .get(`${process.env.REACT_APP_API_END_POINT}${pathname}`, {
      headers: {
        ...config?.headers,
      },
      ...config,
    })
    .then((res) => res.data);
};

export const fetchData = async <T>({
  url,
  options = undefined,
}: {
  url: string;
  options?: AxiosRequestConfig;
}) => {
  // const response: AxiosResponse<T> = await axios(url, options);
  // if (!response.ok) {
  //   throw new Error("Network response was not ok");
  // }
  // return response.json();
  try {
    const response: AxiosResponse<T> = await axios(url, options);
    return response.data;
  } catch (error: unknown) {
    throw new Error("Network response was not ok");
  }
};

export const postData = async <D>(
  path: PathPost,
  data: D,
  config: Parameters<AxiosStatic["post"]>[2] = undefined
) => {
  return await axios.post(`${process.env.REACT_APP_API_END_POINT}${path}`, data, {
    headers: {
      ...config?.headers,
    },
    ...config,
  });
};
