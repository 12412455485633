import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { HeightPX } from "src/constants";
import { totalItemQtySelector } from "src/redux/features/CartSlice";
import { useAppSelector } from "src/redux/store";
import SearchBar from "src/shared/components/SearchBar";

const RightSectionHeader = () => {
  const totalCartItems = useAppSelector(totalItemQtySelector);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: HeightPX.NAV_BAR,
      }}>
      <Box borderRight={"0.5px solid black"} flex={1} height={"100%"}>
        <SearchBar />
      </Box>
      <Link
        to={"/cart"}
        rel="noreferrer"
        style={{
          textDecoration: "none",
          color: "black",
          margin: 0,
        }}>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            pl: 1.25,
            pr: 2,
          }}>
          <ShoppingBagOutlinedIcon sx={{ fontSize: 12 }} />
          <Typography
            fontFamily={"Work Sans"}
            fontSize={11}
            fontWeight={600}>{`GIỎ HÀNG (${totalCartItems})`}</Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default RightSectionHeader;
