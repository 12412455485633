import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { LocalStorageKey } from "src/constants";
import { type RootState } from "src/redux/store";
import { parseArrayJsonLocalStorage } from "src/shared/helpers/common";
import { OrderItem } from "src/types/forms";

export type CartItem = Omit<OrderItem, "discounts" | "image"> &
  Partial<Pick<OrderItem, "discounts">> & {
    imageUrl?: string;
  };

interface CartState {
  items: CartItem[];
  showCart: boolean;
}

const initialState: CartState = {
  items: parseArrayJsonLocalStorage<CartItem>(LocalStorageKey.CART),
  showCart: false,
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    toggleCart: (state, action: PayloadAction<boolean>) => {
      state.showCart = action.payload;
    },
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const addCartItemIndex = state.items.findIndex(
        (item) => item.variant_id === action.payload.variant_id
      );

      if (addCartItemIndex !== -1) {
        const updatedItem = state.items[addCartItemIndex];

        if (updatedItem.stockQuantity > updatedItem.quantity) {
          state.items.splice(addCartItemIndex, 1, {
            ...updatedItem,
            quantity: updatedItem.quantity + 1,
          });
        }
      } else {
        state.items.unshift(action.payload);
      }
      state.showCart = true;
      localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
    },
    updateQuantityCart: (
      state,
      action: PayloadAction<{ type: "increase" | "decrease" } & Pick<CartItem, "variant_id">>
    ) => {
      const updateCartItemIndex = state.items.findIndex(
        (item) => item.variant_id === action.payload.variant_id
      );

      if (updateCartItemIndex !== -1) {
        const updatedItem = state.items[updateCartItemIndex];

        const updatedQuantity: { [K in typeof action.payload.type]: number } = {
          decrease: updatedItem.quantity - 1,
          increase: updatedItem.quantity + 1,
        };

        state.items.splice(updateCartItemIndex, 1, {
          ...updatedItem,
          quantity: updatedQuantity[action.payload.type],
        });

        localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
      }
    },
    deleteItemCart: (state, action: PayloadAction<Pick<CartItem, "variant_id">>) => {
      const deleteCartItemIndex = state.items.findIndex(
        (item) => item.variant_id === action.payload.variant_id
      );

      if (deleteCartItemIndex !== -1) {
        state.items.splice(deleteCartItemIndex, 1);
        localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
      }
    },
    clearCart: (state) => {
      state.items = [];
      localStorage.setItem(LocalStorageKey.CART, JSON.stringify(state.items));
    },
  },
});

export default CartSlice.reducer;
export const { addToCart, toggleCart, updateQuantityCart, deleteItemCart, clearCart } =
  CartSlice.actions;

export const stateDrawerCart = createSelector(
  [(state: RootState) => state.cart.showCart],
  (showCart) => showCart
);

const items = (state: RootState) => state.cart.items;

export const itemsCartSelector = createSelector([items], (items) => items);

export const totalItemQtySelector = createSelector([items], (items) => {
  return items.reduce((total: number, curr: CartItem) => (total += curr.quantity), 0);
});

export const totalQtyLimitSelector = createSelector(
  [items, (_, limit: number) => limit],
  (items, limit) => {
    const total = items.reduce((total: number, curr: CartItem) => (total += curr.quantity), 0);
    return total > limit;
  }
);
