import { useClickOutside } from "@mantine/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { HeightPX, IndexUI } from "src/constants";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import { discoveryItem } from "src/redux/features/DiscoveryDetailSlice";
import { useAppSelector } from "src/redux/store";
import { keyframes, styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: ${IndexUI.POPUP};
`;

const slideIn = keyframes`
  0% {
    transform: translateY(80vh);
  }
  50% {
    transform: translateY(-2vh);
  }
  100% {
    transform: translateY(0vh);
  }
`;

const SlideUpContainer = styled.div`
  animation: ${slideIn} 1s ease-in-out;
  height: calc(100vh - ${HeightPX.NAV_BAR} - 100px);
  display: flex;
  border-top: 0.5px solid black;
  background-color: white;
`;

type DiscoveryDetailModalProps = {
  closeModal: () => void;
};

const DiscoveryDetailModal = ({ closeModal }: DiscoveryDetailModalProps) => {
  const { matchMobileView, matchMobileViewSmall } = useCustomMediaQuery();
  const { description, title, content } = useAppSelector(discoveryItem);

  const ref = useClickOutside(closeModal);

  return (
    <Container>
      <SlideUpContainer ref={ref}>
        <Box
          sx={{
            flex: matchMobileView ? (matchMobileViewSmall ? 1 : 2) : 4,
            display: "flex",
            flexDirection: "column",
            borderRight: "0.5px solid black",
          }}>
          <Box p={2} borderBottom={"0.5px solid black"}>
            <Typography fontWeight={600} style={{ fontSize: "12px" }}>
              {title}
            </Typography>
          </Box>

          <Box overflow={"auto"} className={"post"}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Box>
        </Box>

        <Box flex={1}>
          <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon onClick={closeModal} sx={{ cursor: "pointer" }} />
          </Box>

          <Box
            pt={7}
            p={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography variant="caption">{description}</Typography>
          </Box>
        </Box>
      </SlideUpContainer>
    </Container>
  );
};

export default DiscoveryDetailModal;
