import { CreateOrderRequest } from "src/types/services";

import { useMutation } from "@tanstack/react-query";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import { postData } from "src/utils/service";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreateOrder = ({ onSuccess }: { onSuccess: () => void }) => {
  const showLoading = useShowOverlayLoading();
  const showSnackbar = useShowSnackbar();

  return useMutation({
    mutationKey: ["create-order"],
    mutationFn: (data: CreateOrderRequest) => postData<CreateOrderRequest>("/orders", data),
    onSuccess() {
      showLoading(false);
      onSuccess();
    },
    onError() {
      showLoading(false);
      showSnackbar(
        "Có lỗi trong khi tạo đơn hàng. Vui lòng refresh lại trang hoặc liên hệ với quản trị viên. Xin cảm ơn!",
        "error"
      );
    },
  });
};

export default useCreateOrder;
