import { Box, Typography } from "@mui/material";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import { CART_HEADER_PX } from "src/layouts/CartPageContent/const";

const CheckoutInfoHeader = ({ totalProduct }: { totalProduct: number }) => {
  const { matchMobileViewMedium } = useCustomMediaQuery();

  //  Hardcode height for the line connection from shipping info and checkout info
  return (
    <Box
      p={2}
      pt={0}
      boxSizing={"border-box"}
      alignContent={"end"}
      height={CART_HEADER_PX}
      borderBottom={"0.5px solid black"}>
      {matchMobileViewMedium ? (
        <Typography variant="h4" fontWeight="600" pb={2}>
          VẠC
        </Typography>
      ) : null}
      <Typography fontWeight="600">{totalProduct} sản phẩm</Typography>
    </Box>
  );
};

export default CheckoutInfoHeader;
