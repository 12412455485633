import { Typography } from "@mui/material";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";

const ContactComponent = () => (
  <ContactInfoContentLayout title="LIÊN HỆ">
    <Typography fontSize={12}>ĐỊA CHỈ 497/3 Sư Vạn Hạnh, P12, Q10</Typography>

    <Typography fontSize={12}>
      HOTLINE 0909 92 52 91
      <br />
      HOẠT ĐỘNG TỪ 10AM-10PM
    </Typography>

    <Typography fontSize={12}>EMAIL: vacclosetarchive@gmail.com</Typography>
  </ContactInfoContentLayout>
);

export default ContactComponent;
