import CheckoutInfo from "./CheckoutInfo";
import ShippingInfo from "./ShippingInfo";
import { OrderForm } from "src/types/forms";

import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import BrandingContentAutoplaySection from "src/components/BrandingContentAutoplaySection";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

const CartPageContent = () => {
  const { matchMobileViewMedium } = useCustomMediaQuery();
  const orderFormMethod = useForm<OrderForm>({
    defaultValues: {
      customer: {
        last_name: "",
        first_name: "",
        phone_number: "",
        address: "",
      },
      orderItems: [],
      shipment: {
        province_id: undefined,
        district_id: undefined,
        ward_id: undefined,
        address: "",
        shipmentPrices: 0,
      },
      payment_method: "CASH_ON_DELIVERY",
      note: "",
      voucherField: null,
      extraFeeField: 0,
    },
  });

  return (
    <Stack sx={matchMobileViewMedium ? { overflow: "auto", height: "100vh" } : {}}>
      <BrandingContentAutoplaySection />

      <Box sx={{ display: "flex", flexWrap: "wrap-reverse" }}>
        <Box
          sx={{
            flex: matchMobileViewMedium ? "1 1 100%" : 2,
            borderRight: matchMobileViewMedium ? "none" : "0.5px solid black",
          }}>
          <ShippingInfo {...orderFormMethod} />
        </Box>
        <Box sx={{ flex: matchMobileViewMedium ? "1 1 100%" : 1 }}>
          <CheckoutInfo {...orderFormMethod} />
        </Box>
      </Box>
    </Stack>
  );
};
export default CartPageContent;
