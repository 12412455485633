import { createRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ImageResponse } from "src/types/services";
import styled from "styled-components";
import { reduceUrlImage } from "src/utils/helper";

const RANGE_OFFSET_IMAGE = 40;

const ImageShowItem = styled.img`
  object-fit: cover;
  height: 100%;
  border-bottom: 0.5px solid black;
`;

const ImageContainer = styled(Stack)`
  overflow: auto;
  height: 100%;
  scroll-snap-type: y mandatory;
  align-items: center;
  overflow-x: hidden;
  border-right: 0.5px solid black;
`;

const ImagePreviewScroll = ({ images }: { images: ImageResponse[] }) => {
  const [, setSelectedImageId] = useState<string>(images[0].id);

  const containerRef = createRef<HTMLDivElement>();
  const imgRefs = Array.from({ length: images.length }, () => createRef<HTMLImageElement>());

  const handleOnScrollImage = (currentScrollTop: number) => {
    const focusedImageRef = imgRefs.find((imgRef) => {
      const currentImgRef = imgRef.current;
      if (currentImgRef) {
        return (
          currentScrollTop >= currentImgRef.offsetTop - RANGE_OFFSET_IMAGE &&
          currentScrollTop <= currentImgRef.offsetTop + RANGE_OFFSET_IMAGE
        );
      }

      return false;
    });

    if (focusedImageRef && focusedImageRef.current) {
      setSelectedImageId(focusedImageRef.current.id);
    }
  };

  return (
    <Box display="flex" height="100%" sx={{ flex: 1 }}>
      <ImageContainer
        ref={containerRef}
        onScroll={(e) => handleOnScrollImage((e.target as HTMLButtonElement).scrollTop)}>
        {images.map((item, index) => (
          <ImageShowItem
            ref={imgRefs[index]}
            id={item.id}
            key={item.id}
            src={reduceUrlImage({ originUrl: item.url, width: 800 })}
            style={{ scrollSnapAlign: "center" }}
            alt={`image-${item.file_name}`}
          />
        ))}
      </ImageContainer>
    </Box>
  );
};

export default ImagePreviewScroll;
