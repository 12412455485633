import samplePicture from "src/assets/images/main_pic.jpg";

export const convertStringCurrency = (amount: number = 0) => {
  const VNDFormat = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    maximumFractionDigits: 0,
  });

  return VNDFormat.format(amount);
};

export const handleNullableImage = (sourceImage?: string) => {
  return sourceImage ? sourceImage : samplePicture;
};

export const reduceUrlImage = ({
  originUrl,
  width = 1920,
  quality = 45,
}: {
  originUrl?: string;
  width?: number;
  quality?: number;
}) => {
  if (!originUrl) {
    return samplePicture;
  }

  const [hostUrl, pathUrl] = originUrl.split("upload/");

  return hostUrl + `upload/w_${width}/q_${quality}/` + pathUrl;
};
