import { useQuery } from "@tanstack/react-query";
import { CollectionResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/utils/service";

const useSpotlights = () => {
  const { data, isFetching } = useQuery<QueryDataResponse<CollectionResponse>>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: ["spotlight"],
    queryFn: () =>
      getData({
        path: "/collections/spotlight",
      }),
  });

  return {
    spotlights: data?.items || [],
    isFetching,
  };
};

export default useSpotlights;
