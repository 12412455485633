import { Typography } from "@mui/material";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";

const InfoComponent = () => (
  <ContactInfoContentLayout title="GIỚI THIỆU">
    <Typography fontSize={12}>Rất vui vì đã được gặp bạn ở tủ đồ Online của Vạc</Typography>
    <Typography fontSize={12}>
      Hy vọng bạn sẽ có cơ hội trải nghiệm mua sắm tốt nhất với vô vàn các sản phẩm đã được tuyển
      chọn kĩ lưỡng và thích thú khi nhận được sản phẩm từ cửa hàng, tất cả đều đã được đóng gói cẩn
      thận trước khi đến tay bạn.
    </Typography>
    <Typography fontSize={12}>
      {"Và Vạc cũng mong muốn nhận được lời góp ý, chia sẻ cùng với hình ảnh đến từ bạn <3!"}
    </Typography>
  </ContactInfoContentLayout>
);

export default InfoComponent;
