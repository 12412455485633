import { useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import usePostDetail from "src/hooks/usePostDetail";
import MobileHeader from "src/components/MobileHeader";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import { useCallback } from "react";

const BreadcrumbLink = ({ label, link }: { label: string; link?: string }) => {
  const navigate = useNavigate();

  if (link) {
    return (
      <Link
        underline="hover"
        color="inherit"
        onClick={() => navigate(link)}
        sx={{ cursor: "pointer" }}>
        {label}
      </Link>
    );
  }

  return (
    <Typography color="text.primary" fontSize={12}>
      {label}
    </Typography>
  );
};

const CustomBreadcrumbs = ({ currentTitle }: { currentTitle?: string }) => {
  const editorialBreadcrumbs = [
    { label: "TRANG CHỦ", link: "/" },
    { label: "EDITORIALS", link: "/editorials" },
    { label: currentTitle || "--" },
  ];

  return (
    <Breadcrumbs
      sx={{ mb: 3, p: 3, pb: 0, fontSize: "12px" }}
      separator={<NavigateNextIcon fontSize="small" />}>
      {editorialBreadcrumbs.map((props, index) => (
        <BreadcrumbLink key={index} {...props} />
      ))}
    </Breadcrumbs>
  );
};

const EditorialDetailPageContent = () => {
  const { editorialId } = useParams();
  const { postDetail } = usePostDetail({ id: editorialId });
  const {
    matchViewLarge,
    matchMobileView,
    matchMobileViewMedium,
    matchMobileViewSmall,
    matchMobileViewExtraSmall,
  } = useCustomMediaQuery();

  const getWidthImage = useCallback(() => {
    switch (true) {
      case matchMobileViewExtraSmall:
        return 340;
      case matchMobileViewSmall:
        return 660;
      case matchMobileViewMedium:
        return 900;
      case matchMobileView:
        return 1400;

      default:
        return 1920;
    }
  }, [
    matchViewLarge,
    matchMobileView,
    matchMobileViewMedium,
    matchMobileViewSmall,
    matchMobileViewExtraSmall,
  ]);
  return (
    <Stack>
      <MobileHeader />

      <Box borderBottom={"0.5px solid black"}>
        <CustomBreadcrumbs currentTitle={postDetail?.title} />
      </Box>

      <Box overflow="auto" className="post">
        <Typography variant="body2">
          <div
            dangerouslySetInnerHTML={{
              __html:
                postDetail?.content.replace(/\/upload\//g, `/upload/w_${getWidthImage()}/q_70/`) ||
                "",
            }}
          />
        </Typography>
      </Box>
    </Stack>
  );
};

export default EditorialDetailPageContent;
