import { useQuery } from "@tanstack/react-query";
import { type ProductResponse } from "src/types/services";
import { getData } from "src/utils/service";

type UseFetchProductDetailProps = Partial<{
  id?: string;
  variantId?: string;
  onSuccess?: ((data: ProductResponse) => void) | undefined;
}>;

const useFetchProductDetail = ({ id, variantId, onSuccess }: UseFetchProductDetailProps) => {
  const {
    data: productDetail,
    isFetching,
    error,
  } = useQuery<ProductResponse>({
    refetchOnWindowFocus: false,
    queryKey: ["product-detail", { id, variantId }],
    queryFn: async () => await getData({ path: "/products", slug: id }),
    enabled: Boolean(id),
    onSuccess,
  });

  return {
    error,
    isFetching,
    productDetail,
  };
};

export default useFetchProductDetail;
