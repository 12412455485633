import { Box, Fade, FormControlLabel, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import SizeGuideImage from "src/assets/images/size-guide.jpg";
import FullWithImage from "src/components/FullWithImage";

type CollapseSectionProps = {
  title?: string;
  content?: React.ReactElement;
  borderBottom?: boolean;
};

const CollapseSection = ({ title, content, borderBottom }: CollapseSectionProps) => {
  const [checked, setChecked] = useState(false);

  const handleChange = useCallback(() => {
    setChecked((prev) => !prev);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        sx={{
          mr: 0,
        }}
        control={
          <div
            onClick={handleChange}
            style={{
              width: "100%",
              borderTop: "0.5px solid black",
              borderBottom: borderBottom ? "0.5px solid black" : undefined,
            }}>
            <Box
              mt={1}
              mb={1}
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography fontWeight="600" fontSize={12}>
                  {title}
                </Typography>
              </Box>
              {checked ? <ArrowDropDownIcon /> : <ArrowDropUpTwoToneIcon />}
            </Box>
          </div>
        }
        label=""
      />
      {content && checked && <Fade in={checked}>{content}</Fade>}
    </Box>
  );
};
const GuideInformationSection = () => {
  return (
    <Box mt={2} ml={1}>
      <CollapseSection
        title="Hướng dẫn chọn size"
        content={
          <Box>
            <FullWithImage src={SizeGuideImage} />
          </Box>
        }
      />
      <CollapseSection
        title="Thông tin giao hàng"
        content={
          <Box pt={1}>
            <Typography fontSize={12}>- Miễn phí giao hàng với đơn hàng từ 500k</Typography>
            <Typography fontSize={12}>- Giao hàng nhanh chóng với GHTK</Typography>
          </Box>
        }
        borderBottom
      />
    </Box>
  );
};

export default GuideInformationSection;
