import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RoutesChild from "src/routes";
import Theme from "src/Theme";

const queryClient = new QueryClient();
const App = () => (
  <Theme>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RoutesChild />
      </BrowserRouter>
    </QueryClientProvider>
  </Theme>
);

export default App;
