import { Box } from "@mui/material";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import PostsList from "src/components/PostsList";
import { HeightPX } from "src/constants";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import useFetchNextPageScrollBottom from "src/hooks/useFetchScrollBottom";
import { PostResponse, QueryDataResponse } from "src/types/services";

const DiscoveryPosts = ({
  queryPosts,
  columns,
}: {
  queryPosts: UseInfiniteQueryResult<QueryDataResponse<PostResponse, true>, unknown>;
  columns: number;
}) => {
  const { matchMobileView } = useCustomMediaQuery();
  const handleOnScroll = useFetchNextPageScrollBottom({
    hasNextPage: queryPosts.hasNextPage,
    fetchNextPage: queryPosts.fetchNextPage,
  });

  return (
    <Box
      height={`calc(100vh - ${HeightPX.NAV_BAR} ${matchMobileView ? `- ${HeightPX.MOBILE_HEADER}` : ""} - 1.5px)`} // should fix hard 1.5px
      overflow={"auto"}
      onScroll={handleOnScroll}>
      <PostsList queryResult={queryPosts} columns={columns} />
    </Box>
  );
};

export default DiscoveryPosts;
