import {
  Box,
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  imageListItemBarClasses,
  Typography,
} from "@mui/material";
import { useCallback } from "react";

import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import FullWithImage from "src/components/FullWithImage";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import useSearchProducts from "src/hooks/useSearchProducts";
import useSpotlights from "src/hooks/useSpotlights";
import { reduceUrlImage } from "src/utils/helper";

const sx = {
  imageListItemBar: {
    [`& .${imageListItemBarClasses.titleWrapBelow}`]: {
      py: "6px",
    },
  },
};

const SpotlightSection = () => {
  const { matchMobileViewSmall } = useCustomMediaQuery();
  const searchProducts = useSearchProducts();

  const { spotlights, isFetching } = useSpotlights();

  const handleClickCategory = useCallback(
    (categoryId: string) => {
      searchProducts({ collection: categoryId });
    },
    [searchProducts]
  );

  if (isFetching) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        py={4}
        minHeight={"300px"}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography fontWeight={600} variant={matchMobileViewSmall ? "h5" : "h3"}>
          SPOTLIGHT
        </Typography>

        <Box
          sx={{
            gap: 1,
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
          onClick={() => searchProducts()}>
          <Button sx={{ p: 0, m: 0, textTransform: "inherit" }} variant="text" color="inherit">
            <Typography
              variant="body2"
              sx={matchMobileViewSmall ? { fontSize: 12 } : { paddingRight: 0.5 }}>
              Xem toàn bộ sản phẩm
            </Typography>
            {matchMobileViewSmall ? null : <ArrowOutwardOutlinedIcon fontSize="small" />}
          </Button>
        </Box>
      </Box>

      <ImageList
        variant={matchMobileViewSmall ? "masonry" : "standard"}
        cols={matchMobileViewSmall ? 4 : 6}
        gap={0}
        sx={{ m: 0 }}>
        {spotlights.map(({ id, title, image }) => (
          <ImageListItem
            key={id}
            sx={{
              cursor: "pointer",
              borderRight: "0.5px solid black",
              borderTop: "0.5px solid black",
            }}
            onClick={() => handleClickCategory(id)}>
            <ImageListItemBar
              sx={sx.imageListItemBar}
              subtitle={
                <Typography
                  fontWeight={600}
                  fontSize={12}
                  noWrap
                  sx={{ ml: 2, ...(matchMobileViewSmall ? { fontSize: 10 } : {}) }}>
                  {title}
                </Typography>
              }
              position="below"
            />
            <FullWithImage src={reduceUrlImage({ originUrl: image?.url, width: 250 })} />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default SpotlightSection;
