import { useNavigate } from "react-router-dom";
import { Box, Grow, IconButton, Link, Stack, Typography } from "@mui/material";
import FullWithImage from "src/components/FullWithImage";
import { CartItem } from "src/redux/features/CartSlice";
import { convertStringCurrency, reduceUrlImage } from "src/utils/helper";
import styled from "styled-components";
import { ProductResponse } from "src/types/services";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useState } from "react";

export interface ProductItemProps
  extends Omit<CartItem, "quantity" | "attribute_values">,
    Pick<ProductResponse, "id"> {
  isOutOfStock: boolean;
  onAddToCart: () => void;
  subImageUrl: string;
}

const ProductItemFunction = ({ addToCart }: { addToCart: () => void }) => (
  <Box
    position="absolute"
    top={0}
    right={0}
    display="flex"
    justifyContent="space-between"
    boxSizing={"border-box"}
    zIndex={1}
    width={"100%"}
    p="5%">
    <Box bgcolor={"red"} px={1} alignContent={"center"}>
      <Typography variant="body2" fontWeight={600} color={"white"}>
        SALE 20%
      </Typography>
    </Box>
    {/* <Box></Box> */}
    <IconButton
      color="inherit"
      size="small"
      sx={{
        backgroundColor: "white",
        ":hover": {
          opacity: 0.8,
          backgroundColor: "white",
          color: "inherit",
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        addToCart();
      }}>
      <ShoppingBagOutlinedIcon fontSize={"small"} />
    </IconButton>
  </Box>
);

const OverlayProductItemOutOfStock = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      bgcolor: "#00000012",
      "&:hover": {
        opacity: 0,
      },
    }}>
    <Box bgcolor={"#58cc47"} p={2} borderRadius={"50px"}>
      <Typography color={"white"} variant="body2" fontWeight={600}>
        HẾT HÀNG
      </Typography>
    </Box>
  </Box>
);

const ImageWrapper = styled(Box)`
  display: inline-block;
  overflow: hidden;

  &:not(.out-of-stock, .no-hover):hover img {
    opacity: 0.7;
    transform: scale(1.5);
    transition: transform 0.2s;
    -o-transform: scale(1.5); /* Opera */
    -ms-transform: scale(1.5); /* IE 9 */
    -moz-transform: scale(1.5); /* Firefox */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
  }
`;

const ProductItem = ({
  id,
  imageUrl,
  product_name,
  product_price,
  isOutOfStock,
  subImageUrl,
  onAddToCart,
}: ProductItemProps) => {
  const navigate = useNavigate();
  const [showSubImage, setShowSubImage] = useState(false);

  return (
    <Grow in timeout={1500}>
      <Box
        position="relative"
        sx={{
          borderRight: "0.5px solid #bbb",
          borderBottom: "0.5px solid #bbb",
          cursor: "pointer",
          ...(isOutOfStock ? {} : { cursor: "pointer" }),
        }}
        onClick={() => navigate(`/products/${id}`)}>
        {isOutOfStock ? (
          <OverlayProductItemOutOfStock />
        ) : (
          <ProductItemFunction addToCart={onAddToCart} />
        )}

        <ImageWrapper
          className={isOutOfStock ? "out-of-stock" : "no-hover"}
          onMouseEnter={() => setShowSubImage(true)}
          onMouseLeave={() => setShowSubImage(false)}>
          <FullWithImage
            src={reduceUrlImage({ originUrl: imageUrl, width: 370 })}
            style={{ opacity: showSubImage ? 0 : 1 }}
          />
          <FullWithImage
            src={reduceUrlImage({ originUrl: subImageUrl, width: 370 })}
            style={{ opacity: showSubImage ? 1 : 0, position: "absolute", top: 0 }}
          />
        </ImageWrapper>

        <Stack px={1} pb={0.5}>
          <Link color="inherit" underline="hover">
            <Typography
              variant="body2"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {product_name}
            </Typography>
          </Link>
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
            }}>
            {!isOutOfStock ? (
              <Typography
                variant="body2"
                fontSize={12}
                mr={1}
                sx={{
                  textDecoration: "line-through",
                }}>
                {convertStringCurrency(product_price * 1.25)}
              </Typography>
            ) : null}
            <Typography variant="body2">{convertStringCurrency(product_price)}</Typography>
          </Box>
        </Stack>
      </Box>
    </Grow>
  );
};

export default ProductItem;
