import { Box } from "@mui/material";
import PostsList from "src/components/PostsList";
import useFetchPosts from "src/hooks/useFetchPosts";
import useFetchNextPageScrollBottom from "src/hooks/useFetchScrollBottom";

const Articles = () => {
  const queryPosts = useFetchPosts({ type: "DAILY_UPDATE", limitItem: 10 });

  const handleOnScroll = useFetchNextPageScrollBottom({
    hasNextPage: queryPosts.hasNextPage,
    fetchNextPage: queryPosts.fetchNextPage,
  });

  return (
    <Box overflow={"auto"} onScroll={handleOnScroll}>
      <PostsList queryResult={queryPosts} columns={1} />
    </Box>
  );
};

export default Articles;
