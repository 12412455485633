import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Theme,
  Typography,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReactElement } from "react";

import { accordionSummaryClasses } from "@mui/material/AccordionSummary";
import { useNavigate } from "react-router-dom";

const sx = {
  accordionSummary: (theme: Theme) => ({
    p: theme.spacing(0),
    minHeight: "fit-content",
    justifyContent: "flex-start",
    [`& .${accordionSummaryClasses.content}`]: {
      margin: theme.spacing(0),
      flexGrow: 0,
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
      color: "black",
    },
  }),
};

type CustomAccordionProps = {
  title: string;
  link: string;
  content?: ReactElement | string;
};

const CustomAccordion = ({ title, link, content }: CustomAccordionProps) => {
  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate(link);
  };

  if (!content) {
    return (
      <Link underline="hover" color="inherit" onClick={handleLinkClick} sx={{ cursor: "pointer" }}>
        <Typography variant="body2" fontWeight={600}>
          {title}
        </Typography>
      </Link>
    );
  }

  return (
    <Box display={"flex"} alignItems={"start"} justifyContent={"space-between"}>
      <Accordion
        elevation={0}
        disableGutters
        sx={{ background: "inherit", "::before": { background: "none" } }}>
        <AccordionSummary
          sx={sx.accordionSummary}
          expandIcon={
            content ? <ArrowDropDownIcon color="inherit" sx={{ fontSize: 20 }} /> : undefined
          }
          aria-controls="panel2-content"
          id="panel2-header">
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{ ":hover": { textDecoration: "underline" } }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, m: 0 }}>{content}</AccordionDetails>
      </Accordion>
      {link ? (
        <Button
          sx={{
            p: 0.2,
            m: 0,
            color: "inherit",
            display: "flex",
            justifyContent: "flex-end",
            textTransform: "inherit",
          }}
          onClick={handleLinkClick}
          color="inherit">
          <Typography variant="body2" fontSize={12}>
            Xem tất cả
          </Typography>
        </Button>
      ) : null}
    </Box>
  );
};

export default CustomAccordion;
