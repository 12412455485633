import Filters from "src/layouts/ProductsPageContent/Filters";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import { HeightPX, IndexUI } from "src/constants";

const ProductsHeader = () => {
  const { matchMobileView, matchMobileViewSmall } = useCustomMediaQuery();
  const navigate = useNavigate();

  return (
    <Grid
      container
      borderBottom={"0.5px solid black"}
      sx={{
        position: "sticky",
        top: matchMobileView ? HeightPX.MOBILE_HEADER : HeightPX.NAV_BAR,
        zIndex: IndexUI.STICKY_ELEMENT,
        backgroundColor: "#f7f7f7",
      }}>
      <Grid item xs={4} sm={6} md={4} lg={3} xl={3}>
        <Box
          display={"flex"}
          alignItems={"center"}
          height={"100%"}
          pl={2}
          borderRight={"0.5px solid black"}>
          <Breadcrumbs sx={{ fontSize: "12px" }} separator={<NavigateNextIcon fontSize="small" />}>
            <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
              <Typography fontSize={12} fontWeight={600}>
                TRANG CHỦ
              </Typography>
            </Box>

            {!matchMobileViewSmall ? (
              <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/products")}>
                <Typography fontSize={12}>SẢN PHẨM</Typography>
              </Box>
            ) : null}
          </Breadcrumbs>
        </Box>
      </Grid>
      <Grid item xs={8} sm={6} md={8} lg={9} xl={9}>
        <Filters />
      </Grid>
    </Grid>
  );
};

export default ProductsHeader;
