import { Box, Typography } from "@mui/material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import {
  DiscoveryDetailItem,
  openDiscoveryDetailModal,
} from "src/redux/features/DiscoveryDetailSlice";
import { PostResponse } from "src/types/services";
import { reduceUrlImage } from "src/utils/helper";
import styled from "styled-components";

interface PostItemProps {
  postItem: PostResponse;
  postItemWidth?: number;
}

const ThreeLinesEllipsisText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const PostItemImg = styled("img")`
  display: block;
  width: 100%;
  height: auto;
`;

const PostItem = ({ postItem, postItemWidth }: PostItemProps) => {
  const { matchMobileView, matchMobileViewMedium, matchViewLarge } = useCustomMediaQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { title, description, topic_image } = postItem;

  const onItemClick = useCallback(
    ({ id, title, content, description, type }: PostResponse) => {
      if (type === "EDITORIAL") {
        navigate(`/editorial-detail/${id}`);
      } else {
        const discoveryItem: DiscoveryDetailItem = {
          id,
          description,
          title,
          content,
        };

        dispatch(openDiscoveryDetailModal(discoveryItem));
      }
    },
    [dispatch, navigate]
  );

  return (
    <Box
      sx={{
        cursor: "pointer",
        borderBottom: "0.5px solid black",
      }}
      onClick={() => onItemClick(postItem)}>
      <PostItemImg
        src={reduceUrlImage({
          originUrl: topic_image.url,
          width:
            matchMobileView && !matchMobileViewMedium
              ? 800
              : postItemWidth && matchViewLarge
                ? postItemWidth
                : 500,
        })}
        alt={title}
        loading="lazy"
      />
      <Box p={1} pl={2.5} textAlign={"left"}>
        <Typography fontWeight={600} variant="body2">
          {title}
        </Typography>
        <ThreeLinesEllipsisText variant="caption">{description}</ThreeLinesEllipsisText>
      </Box>
    </Box>
  );
};

export default PostItem;
