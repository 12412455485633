import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Work Sans"].join(","),
  },

  palette: {
    divider: "black",
  },
});

const Theme = ({ children }: { children: React.ReactElement }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
