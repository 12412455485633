import { useEffect } from "react";
import { ProductResponse, VariantResponse } from "src/types/services";

const useSelectDefaultVariant = ({
  productItem,
  defaultVariantId,
  onSelect,
}: {
  productItem?: ProductResponse;
  onSelect: (variant?: VariantResponse) => void;
  defaultVariantId?: string;
}) => {
  useEffect(() => {
    if (productItem && !defaultVariantId) {
      const { variants } = productItem;

      const firstVariantInStock = variants.find(({ stock_quantity }) => stock_quantity > 0);

      if (firstVariantInStock) {
        onSelect(firstVariantInStock);
      }
    } else if (!productItem && defaultVariantId) {
      onSelect();
    }
  }, [productItem, defaultVariantId, onSelect]);
};

export default useSelectDefaultVariant;
