import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { SortBy, ProductSearchKey, ProductStatus } from "src/types/product-filter";
import { generateUrlWithQueryParams } from "src/shared/helpers/queryParams";
import { ProductResponse, QueryDataResponse } from "src/types/services";
import { fetchData, getApiHost } from "src/utils/service";
import { LIMIT_PRODUCT_ITEM } from "src/constants";

const useFetchProducts = () => {
  let [searchParams] = useSearchParams();

  const productCollectionFilter = searchParams.get(ProductSearchKey.COLLECTION);
  const [sortKey, sortValue] = (searchParams.get(ProductSearchKey.SORT_BY) || SortBy.LATEST).split(
    "-"
  );
  const productSearch = searchParams.get(ProductSearchKey.SEARCH);
  const productStatusFilter = searchParams.get(ProductSearchKey.STATUS);
  const productPagingFilter = searchParams.get(ProductSearchKey.PAGING);

  const { data, isFetching } = useQuery<QueryDataResponse<ProductResponse, true>>({
    refetchOnWindowFocus: false,
    queryKey: [
      "products",
      {
        productCollectionFilter,
        sortKey,
        sortValue,
        productStatusFilter,
        productSearch,
        productPagingFilter,
      },
    ],
    queryFn: () =>
      fetchData({
        url: generateUrlWithQueryParams(`${getApiHost()}/products`, {
          limit: `${LIMIT_PRODUCT_ITEM}`,
          offset: `${LIMIT_PRODUCT_ITEM * (Number(productPagingFilter) - 1)}`,
          is_visible: "1",
          collection_ids: `${productCollectionFilter ? `["${productCollectionFilter}"]` : []}`,
          stock_status: productStatusFilter ? productStatusFilter : ProductStatus.AVAILABLE,
          name: productSearch ? productSearch : "",
          ...(sortKey === "price_sort_dir"
            ? { [sortKey]: sortValue }
            : {
                sort: `p.${sortKey}`,
                sort_dir: sortValue,
              }),
        }),
      }),
  });

  return {
    productsData: data?.items || [],
    isFetching,
    total: data?.paging.total || 0,
  };

  // store for useInfiniteQuery
  // return useInfiniteQuery<QueryDataResponse<ProductResponse, true>>({
  //   refetchOnWindowFocus: false,
  //   queryKey: [
  //     "products",
  //     { productCollectionFilter, sortKey, sortValue, productStatusFilter, productSearch },
  //   ],
  //   queryFn: ({ pageParam = 0 }) =>
  //     fetchData({
  //       url: generateUrlWithQueryParams(`${getApiHost()}/products`, {
  //         limit: `${LIMIT_ITEM}`,
  //         offset: `${LIMIT_ITEM * pageParam}`,
  //         is_visible: "1",
  //         collection_ids: `${productCollectionFilter ? `["${productCollectionFilter}"]` : []}`,
  //         stock_status: productStatusFilter ? productStatusFilter : ProductStatus.AVAILABLE,
  //         name: productSearch ? productSearch : "",
  //         ...(sortKey === "price_sort_dir"
  //           ? { [sortKey]: sortValue }
  //           : {
  //               sort: `p.${sortKey}`,
  //               sort_dir: sortValue,
  //             }),
  //       }),
  //     }),
  //   getNextPageParam: (lastFetch, fetchPages) =>
  //     lastFetch.items.length === LIMIT_ITEM &&
  //     LIMIT_ITEM * fetchPages.length < lastFetch.paging.total
  //       ? fetchPages.length
  //       : undefined,
  // });
};

export default useFetchProducts;
