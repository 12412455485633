import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

const ContactInfoContentLayout = ({
  children,
  title,
  noPx = false,
}: PropsWithChildren<{ title: string; noPx?: boolean }>) => (
  <Stack sx={{ overflow: "auto", p: 3, pl: 2.5, pr: 6.5, height: "100vh", px: noPx ? 0 : 3 }}>
    <Typography sx={{ fontWeight: 600, fontSize: 20, px: noPx ? 3 : 0 }}>{title}</Typography>
    <Stack pt={2} gap={1}>
      {children}
    </Stack>
  </Stack>
);

export default ContactInfoContentLayout;
