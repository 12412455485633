import FullWithImage from "src/components/FullWithImage";
import useFetchHomePageInfo from "src/hooks/useFetchHomePageInfo";
import { reduceUrlImage } from "src/utils/helper";

const HomePageInfo = () => {
  const { banners } = useFetchHomePageInfo();

  return (
    <>
      <FullWithImage src={reduceUrlImage({ originUrl: banners })} />
    </>
  );
};

export default HomePageInfo;
