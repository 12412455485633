import { Typography } from "@mui/material";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";

const ShippingAndPaymentComponent = () => (
  <ContactInfoContentLayout title="THANH TOÁN">
    <Typography fontSize={12}>
      Cửa hàng sẽ xác minh lại thông tin đơn hàng qua số điện thoại của bạn nhé! Đối với trường hợp:
    </Typography>
    <Typography fontSize={12}>
      <b>· SHIP COD:</b> Sau khi đơn hàng được giao đến, bạn có thể sử dụng các phương thức thanh
      toán để thanh toán đơn hàng
    </Typography>
    <Typography fontSize={12}>
      <b>· THANH TOÁN ONLINE:</b> Thông tin thanh toán sẽ được cửa hàng cung cấp qua các kênh hỗ trợ
      là FACEBOOK và INSTAGRAM. Sau khi thanh toán thành công Vạc sẽ xác nhận lại thông tin và đóng
      gói đơn hàng
    </Typography>

    <Typography sx={{ fontWeight: 600, fontSize: 20, mt: 2 }}>GIAO HÀNG</Typography>

    <Typography fontSize={12} pt={2}>
      Sau khi hoàn tất đơn hàng và lên đơn thành công, Vạc sẽ gửi bạn mã vận đơn để bạn có thể dàng
      kiểm tra “đường đi” của đơn hàng( Cửa hàng sử dụng các đơn vị vận chuyển như GHN, GHTK,... để
      giao nhận đơn bạn nhé!)
    </Typography>
  </ContactInfoContentLayout>
);

export default ShippingAndPaymentComponent;
