import { PostResponse, QueryDataResponse } from "src/types/services";

import { getData } from "src/utils/service";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PostTypeKey } from "src/types/helpers";

const LIMIT_ITEM = 6;

const useFetchPosts = ({ type, limitItem }: { type: PostTypeKey; limitItem?: number }) => {
  return useInfiniteQuery<QueryDataResponse<PostResponse, true>>({
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryKey: ["posts", { type }],
    queryFn: ({ pageParam = 0 }) =>
      getData({
        path: "/posts",
        config: {
          params: {
            limit: `${limitItem ? limitItem : LIMIT_ITEM}`,
            offset: `${pageParam}`,
            sort: "created_at",
            sort_dir: "desc",
            type,
          },
        },
      }),
    getNextPageParam: (lastFetch) => (limitItem ? undefined : lastFetch.paging?.next_page?.offset),
  });
};

export default useFetchPosts;
