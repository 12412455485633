import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, Ref, useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BrandingContentAutoplaySection from "src/components/BrandingContentAutoplaySection";
import { HeightPX, IndexUI } from "src/constants";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import useFetchMenus from "src/hooks/useFetchMenus";
import useSearchProducts from "src/hooks/useSearchProducts";
import SearchBar from "src/shared/components/SearchBar";
import { getNestedMenuByKey } from "src/utils/menu";
import { ProductSearchKey } from "src/types/product-filter";

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MenuDialog = ({
  openMenu,
  handleCloseMenu,
}: {
  openMenu: boolean;
  handleCloseMenu: () => void;
}) => {
  const searchProducts = useSearchProducts();
  const { menusData, isFetchingMenus } = useFetchMenus();
  const leftMenuNestedData = getNestedMenuByKey("left-menu", menusData);

  const handleClickCategory = useCallback(
    (categoryId: string) => {
      searchProducts({ collection: categoryId });
    },
    [searchProducts]
  );

  const categoriesData = useMemo(() => {
    if (!leftMenuNestedData || !("sub_menus" in leftMenuNestedData)) {
      return [];
    }

    return leftMenuNestedData.sub_menus;
  }, [leftMenuNestedData]);

  return (
    <Dialog open={openMenu} fullScreen onClose={handleCloseMenu} TransitionComponent={Transition}>
      <Box
        sx={{
          height: `calc(${HeightPX.MOBILE_HEADER} + 26px)`,
          minHeight: `calc(${HeightPX.MOBILE_HEADER} + 26px)`,
        }}></Box>
      {isFetchingMenus ? (
        <CircularProgress />
      ) : categoriesData.length === 0 ? (
        <Typography fontSize={13}>No data category</Typography>
      ) : (
        <>
          {categoriesData.map(({ collection: { title }, sub_menus: subGroupMenus }, menuIndex) => (
            <CustomAccordion disableGutters key={`menu-${menuIndex}`}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon color="inherit" fontSize="small" />}
                aria-controls="menu-content"
                id="menu-header">
                <Typography variant="body2" fontWeight={600}>
                  {title}
                </Typography>
              </AccordionSummary>

              {subGroupMenus.map((subGroupMenu, subMenuIndex) => (
                <AccordionDetails key={`submenu-item-${subMenuIndex}`}>
                  {subGroupMenu.sub_menus.map(
                    ({ collection: { title: subMenuTitle, id: subMenuId } }, indexChild) => (
                      <Box
                        width="fit-content"
                        sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
                        onClick={() => {
                          handleClickCategory(subMenuId);
                          handleCloseMenu();
                        }}
                        key={`category-name-${indexChild}`}>
                        <Typography fontSize={13} lineHeight={1.1}>
                          {subMenuTitle}
                        </Typography>
                      </Box>
                    )
                  )}
                </AccordionDetails>
              ))}
            </CustomAccordion>
          ))}
        </>
      )}
    </Dialog>
  );
};

const NavigateButton = ({ label, link }: { label: string; link: string }) => {
  const navigate = useNavigate();

  return (
    <Box flex={1} borderRight={"0.5px solid black"} textAlign={"center"}>
      <Button
        sx={(theme) => ({
          whiteSpace: "nowrap",
          fontSize: 12,
          px: 0.5,
          color: "black",
          [theme.breakpoints.down("sm")]: {
            px: 0.2,
          },
        })}
        onClick={() => navigate(link)}>
        {label}
      </Button>
    </Box>
  );
};

const navigateItems: Record<"label" | "link", string>[] = [
  {
    label: "KHÁM PHÁ",
    link: "/discovery",
  },
  {
    label: "EDITORIAL",
    link: "/editorials",
  },
  {
    label: "SẢN PHẨM",
    link: "/products",
  },
];

interface MobileHeaderProps {
  hideAutoplayBrandingBigScreen?: boolean;
}
const MobileHeader = ({ hideAutoplayBrandingBigScreen = false }: MobileHeaderProps) => {
  const [openMenu, setOpenMenu] = useState(false);

  const { matchMobileView } = useCustomMediaQuery();
  const navigate = useNavigate();

  const [searchFullSize, setSearchFullSize] = useState(false);

  let [searchParams] = useSearchParams();
  const productSearch = searchParams.get(ProductSearchKey.SEARCH);
  const [keyword, setKeyword] = useState<string>(productSearch || "");

  const searchProducts = useSearchProducts();
  const onSearch = () => {
    if (keyword) {
      searchProducts({ search: keyword });
      setSearchFullSize(false);
    }
  };

  if (!matchMobileView) {
    if (hideAutoplayBrandingBigScreen) {
      return <></>;
    }

    return (
      <Stack
        position={"sticky"}
        top={0}
        sx={{ background: "black" }}
        zIndex={IndexUI.STICKY_ELEMENT}>
        <Box flex={3} overflow={"hidden"}>
          <BrandingContentAutoplaySection />
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        position={"sticky"}
        top={0}
        sx={{ background: "#f7f7f7" }}
        zIndex={IndexUI.STICKY_ELEMENT}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pt: 1.5,
            borderBottom: "0.5px solid black",
          }}>
          <Box flex={1} px={2} display="flex" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open menu"
              onClick={() => setOpenMenu((prev) => !prev)}
              edge="start">
              {!openMenu ? <MenuIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
            </IconButton>
            <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
              <Typography fontSize={16} fontWeight={600} noWrap>
                VẠC&apos;s CLOSET
              </Typography>
            </Link>
          </Box>
          <Box flex={3} overflow={"hidden"}>
            <BrandingContentAutoplaySection sx={{ background: "#f7f7f7", color: "black" }} />
          </Box>
          <Box px={1} alignContent={"center"}>
            <IconButton onClick={() => navigate("/cart")}>
              <ShoppingBagOutlinedIcon sx={{ fontSize: 20, color: "black" }} />
            </IconButton>
          </Box>
        </Box>
        {searchFullSize ? (
          <Grid container borderBottom={"0.5px solid black"}>
            <Grid item xs={10} borderRight={"0.5px solid black"} py={1.125}>
              <SearchBar
                updateKeyword={(value) => setKeyword(value)}
                onOutFocus={() => setSearchFullSize(false)}
              />
            </Grid>
            <Grid item xs={2} borderRight={"0.5px solid black"} textAlign={"center"}>
              <IconButton onClick={onSearch} size="small">
                <SearchOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container borderBottom={"0.5px solid black"}>
            <Grid item xs={3} md={4} lg={3} xl={3} borderRight={"0.5px solid black"}>
              <SearchBar onFocus={() => setSearchFullSize(true)} />
            </Grid>
            <Grid item xs={9} md={8} lg={9} xl={9}>
              <Box display={"flex"}>
                {navigateItems.map((navItem) => (
                  <NavigateButton {...navItem} key={navItem.link} />
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
      </Stack>

      <MenuDialog openMenu={openMenu} handleCloseMenu={() => setOpenMenu(false)} />
    </>
  );
};

export default MobileHeader;
