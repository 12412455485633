import { useDispatch } from "react-redux";
import { addToCart, CartItem } from "src/redux/features/CartSlice";
import { ProductResponse } from "src/types/services";

const useAddCartStorageByVariantId = () => {
  const dispatch = useDispatch();

  const onAddToCart = ({
    variantId,
    productItem,
  }: {
    productItem?: ProductResponse;
    variantId: string;
  }) => {
    if (productItem) {
      const { variants, name, images } = productItem;
      const selectedVariant = variants.find(({ id }) => variantId === id);

      if (selectedVariant) {
        const { attributes, retail_price, id } = selectedVariant;
        const cartItem: CartItem = {
          product_name: name,
          product_price: retail_price,
          quantity: 1,
          variant_id: id,
          stockQuantity: selectedVariant.stock_quantity,
          imageUrl: images?.length ? images[0].url : "",
          attribute_values: attributes.map(({ name, value }) => ({
            attribute_name: name,
            attribute_value: value,
          })),
        };

        dispatch(addToCart(cartItem));
      }
    }
  };

  return { onAddToCart };
};

export default useAddCartStorageByVariantId;
