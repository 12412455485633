// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { EffectCoverflow, Pagination, Scrollbar } from "swiper/modules";
import { ImageResponse } from "src/types/services";
import { reduceUrlImage } from "src/utils/helper";
import { Box } from "@mui/material";

const ImageListHorizontalScroll = ({ images }: { images: ImageResponse[] }) => {
  return (
    <Box>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        effect={"coverflow"}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Scrollbar]}
        className="mySwiper">
        {images.map((img) => (
          <SwiperSlide key={img.id}>
            <img
              style={{ height: "75vh", width: "100vw", display: "block", objectFit: "cover" }}
              src={reduceUrlImage({ originUrl: img.url, width: 900 })}
              alt="swiper index th"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ImageListHorizontalScroll;
