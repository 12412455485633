import { Box, Typography } from "@mui/material";
import MobileHeader from "src/components/MobileHeader";
import { HeightPX } from "src/constants";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

const DiscoveryHeader = () => {
  const { matchMobileView } = useCustomMediaQuery();

  return (
    <>
      <MobileHeader hideAutoplayBrandingBigScreen />
      <Box
        display={"flex"}
        borderBottom={"0.5px solid black"}
        textAlign={"center"}
        height={HeightPX.NAV_BAR}
        alignItems={"center"}>
        <Box
          sx={{
            flex: 1,
            borderRight: "0.5px solid black",
            height: "100%",
            alignContent: "center",
          }}>
          <Typography fontWeight={600} fontSize={12}>
            MIX-MATCH
          </Typography>
        </Box>
        <Box sx={{ flex: matchMobileView ? 1 : 3, height: "100%", alignContent: "center" }}>
          <Typography fontWeight={600} fontSize={12}>
            DAILY-UPDATE
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default DiscoveryHeader;
