import { useMemo } from "react";
import { ImageResponse, ProductResponse } from "src/types/services";

const useMergeAllProductImages = (productItem?: ProductResponse) => {
  const images = useMemo(() => {
    const imagesResult: ImageResponse[] = [];

    if (!productItem) {
      return imagesResult;
    }

    const { images: imagesProduct, variants } = productItem;

    const variantImages = variants.reduce(
      (imageList, variant) => (variant?.image ? [variant.image, ...imageList] : imageList),
      [] as ImageResponse[]
    );

    imagesResult.push(...variantImages, ...(imagesProduct ? imagesProduct : []));

    return imagesResult;
  }, [productItem]);

  return images;
};

export default useMergeAllProductImages;
