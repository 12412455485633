import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

import ExpandableProductList from "src/components/ExpandableProductList";
import MobileHeader from "src/components/MobileHeader";
import { HeightPX, LIMIT_PRODUCT_ITEM } from "src/constants";
import useFetchProducts from "src/hooks/useFetchProducts";
import ProductListPagination from "src/layouts/ProductsPageContent/ProductListPagination";
import ProductsHeader from "src/layouts/ProductsPageContent/ProductsHeader";

const ProductsPageContent = () => {
  const { productsData, isFetching, total } = useFetchProducts();
  const [countPagination, setCountPagination] = useState<number>(-1);

  useEffect(() => {
    if (total !== countPagination && total !== 0) {
      setCountPagination(Math.ceil(total / LIMIT_PRODUCT_ITEM));
    }
  }, [countPagination, total]);

  return (
    <Stack sx={{ position: "relative" }}>
      <Stack
        sx={{
          position: "relative",
          height: `calc(100vh - ${HeightPX.PRODUCT_NAVIGATION})`,
          overflowY: "auto",
        }}>
        <MobileHeader />
        <ProductsHeader />

        <ExpandableProductList isFetching={isFetching} productsData={productsData} />
      </Stack>
      <ProductListPagination count={countPagination} />
    </Stack>
  );
};

export default ProductsPageContent;
