import { useQuery } from "@tanstack/react-query";
import { OrderForm } from "src/types/forms";
import { DistrictResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/utils/service";

interface UseDistrictsProps
  extends Partial<Pick<NonNullable<OrderForm["shipment"]>, "province_id">> {}
const useFetchDistricts = ({ province_id }: UseDistrictsProps) => {
  const { data, isFetching } = useQuery<QueryDataResponse<DistrictResponse, false>>({
    enabled: Boolean(province_id),
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryKey: ["districts", province_id],
    queryFn: () =>
      getData({
        path: "/public/districts",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort_by: "created_at",
            sort_dir: "desc",
            province_id: province_id,
          },
        },
      }),
  });

  return {
    districtsData: data ? data.items : [],
    isFetchingDistricts: isFetching,
  };
};

export default useFetchDistricts;
