import { Box, Stack, Typography } from "@mui/material";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import PostsColumn from "src/components/PostsList/PostsColumn";
import { PostResponse, QueryDataResponse } from "src/types/services";

interface PostListProps {
  queryResult: UseInfiniteQueryResult<QueryDataResponse<PostResponse, true>, unknown>;
  columns: number; // support up to 3,
  appliedCustomWith?: boolean;
}
const PostsList = ({ queryResult, columns, appliedCustomWith }: PostListProps) => {
  const { data } = queryResult;

  const _posts = data?.pages.flatMap((page) => page?.items).filter((item) => item);

  if (!_posts || !_posts.length) {
    return (
      <Box
        p={2}
        flex={1}
        display="flex"
        textAlign="center"
        flexDirection="column"
        justifyContent="center">
        <Typography variant="h3" fontWeight={600}>
          Chưa có bài viết
        </Typography>
        <Typography sx={{ mt: 1 }}>Vui lòng thử lại sau, xin cảm ơn!</Typography>
      </Box>
    );
  }

  return (
    <Box display={"flex"}>
      {columns > 0 && columns <= 3 && (
        <>
          {[...Array(columns < _posts.length ? columns : _posts.length)].map((_, columnIndex) => (
            <Stack
              key={columnIndex}
              flex={1}
              borderLeft={columnIndex > 0 ? "0.5px solid black" : undefined}>
              <PostsColumn
                posts={_posts.filter((_, index) => index % columns === columnIndex)}
                postItemWidth={columns < 3 && appliedCustomWith ? 800 : undefined}
              />
            </Stack>
          ))}
        </>
      )}
    </Box>
  );
};

export default PostsList;
