import { useQuery } from "@tanstack/react-query";
import { HomePageInfoResponse } from "src/types/services";
import { getData } from "src/utils/service";

const useFetchHomePageInfo = () => {
  const { data } = useQuery<HomePageInfoResponse>({
    staleTime: 1 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryKey: ["home-page"],
    queryFn: () =>
      getData({
        path: "/home-page",
      }),
  });

  return {
    banners: data?.image_banner || "",
    carousels: data?.image_carousels || [],
    brandingContent: data?.scroll_branding_content || "",
  };
};

export default useFetchHomePageInfo;
