import {
  Box,
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  imageListItemBarClasses,
  Typography,
} from "@mui/material";

import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";

import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import useSearchProducts from "src/hooks/useSearchProducts";
import useSignatures from "src/hooks/useSignatures";
import { reduceUrlImage } from "src/utils/helper";

const sx = {
  imageListItemBar: {
    [`& .${imageListItemBarClasses.titleWrapBelow}`]: {
      py: "6px",
    },
  },
};

const Signatures = () => {
  const { signatures, isFetching } = useSignatures();
  const searchProducts = useSearchProducts();
  const { matchMobileViewSmall } = useCustomMediaQuery();

  if (isFetching) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        py={4}
        minHeight={"300px"}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ImageList gap={0} sx={{ mt: 0 }}>
      {signatures.map(({ id, image, title }, index) => (
        <ImageListItem
          key={id}
          sx={{
            cursor: "pointer",
            ":hover": {
              color: "white",
              backgroundColor: "black",
            },
            borderTop: index < 2 ? "0.5px solid black" : undefined,
            borderBottom: "0.5px solid black",
            borderLeft: index % 2 ? "0.5px solid black" : undefined,
          }}>
          <img
            alt="Title"
            src={reduceUrlImage({ originUrl: image?.url, width: 800 })}
            srcSet={reduceUrlImage({ originUrl: image?.url, width: 800 })}
            onClick={() => searchProducts({ collection: id })}
          />
          <ImageListItemBar
            sx={sx.imageListItemBar}
            title={
              <Box
                sx={{
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => searchProducts({ collection: id })}>
                <Typography
                  pr={1}
                  variant="body2"
                  fontWeight={600}
                  sx={matchMobileViewSmall ? { fontSize: 12 } : {}}>
                  {title}
                </Typography>
                {matchMobileViewSmall ? null : (
                  <Button
                    sx={{
                      p: 0,
                      m: 0,
                      color: "inherit",
                      display: "flex",
                      justifyContent: "flex-end",
                      textTransform: "inherit",
                    }}
                    color="inherit">
                    <Typography variant="body2" pr={0.5}>
                      Xem thêm
                    </Typography>
                    <ArrowOutwardOutlinedIcon fontSize="small" />
                  </Button>
                )}
              </Box>
            }
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
export default Signatures;
