import { useQuery } from "@tanstack/react-query";
import { OrderForm } from "src/types/forms";
import { QueryDataResponse, WardResponse } from "src/types/services";
import { getData } from "src/utils/service";

interface UseWardsProps extends Partial<Pick<NonNullable<OrderForm["shipment"]>, "district_id">> {}

const useFetchWards = ({ district_id }: UseWardsProps) => {
  const { data, isFetching } = useQuery<QueryDataResponse<WardResponse, false>>({
    enabled: Boolean(district_id),
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryKey: ["wards", district_id],
    queryFn: () =>
      getData({
        path: "/public/wards",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort_by: "created_at",
            sort_dir: "desc",
            district_id: district_id,
          },
        },
      }),
  });

  return {
    wardsData: data ? data.items : [],
    isFetchingWards: isFetching,
  };
};

export default useFetchWards;
