import SizeGuideImage from "src/assets/images/order-guide.jpg";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";
import FullWithImage from "src/components/FullWithImage";

const OrderGuideComponent = () => {
  return (
    <ContactInfoContentLayout title="HƯỚNG DẪN MUA HÀNG" noPx>
      <FullWithImage src={SizeGuideImage} />
    </ContactInfoContentLayout>
  );
};

export default OrderGuideComponent;
