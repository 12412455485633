import { Box, Grid, Typography } from "@mui/material";

import ProductItem from "src/components/ExpandableProductList/ProductItem";
import useFetchProducts from "src/hooks/useFetchProducts";
import { getMappingProductVariantInfo } from "src/utils/product";
import { useState } from "react";
import SelectableVariantCartPopup from "src/components/ExpandableProductList/SelectableVariantCartPopup";
import { ProductResponse } from "src/types/services";
import { CartItem } from "src/redux/features/CartSlice";
import useAddCartStorageByVariantId from "src/hooks/useAddCartStorageByVariantId";
import ExpandableProductListLoading from "src/components/ExpandableProductList/ExpandableProductListLoading";

const ExpandableProductList = ({
  isFetching,
  productsData,
}: {} & Omit<ReturnType<typeof useFetchProducts>, "total">) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductResponse>();

  const { onAddToCart } = useAddCartStorageByVariantId();

  const openProductVariantsSelection = (product: ProductResponse) => {
    setSelectedProduct(product);
  };

  const addProductVariantToCart = (variantId: string, productItem?: ProductResponse) => {
    if (variantId) {
      onAddToCart({ productItem: productItem ? productItem : selectedProduct, variantId });
    }

    setSelectedProduct(undefined);
  };

  if (isFetching) {
    return <ExpandableProductListLoading />;
  }

  if (!isFetching && productsData.length === 0) {
    return (
      <Box
        flex={1}
        display="flex"
        textAlign="center"
        flexDirection="column"
        justifyContent="center">
        <Typography variant="h2" fontWeight={600}>
          Không tìm thấy kết quả
        </Typography>
        <Typography sx={{ mt: 2 }}>Chúng tôi không phát hiện sản phẩm bạn đang tìm kiếm</Typography>
        <Typography>Hãy thử lại với kết quả khác</Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid container>
        {productsData.map((product) => {
          const productVariantInfo = getMappingProductVariantInfo(product, 0);
          if (!productVariantInfo) {
            return null;
          }

          const {
            id,
            name,
            imageUrl,
            isOutOfStock,
            attributes,
            variantPrice,
            variantId,
            subImageUrl,
            stockQuantity,
          } = productVariantInfo;

          const variantItem: CartItem = {
            attribute_values: attributes,
            imageUrl,
            product_name: name,
            product_price: variantPrice,
            quantity: 1,
            variant_id: variantId,
            stockQuantity,
          };

          return (
            <Grid item key={`product-item-${id}`} xs={6} md={4} lg={3} xl={3}>
              <ProductItem
                id={id}
                isOutOfStock={isOutOfStock}
                onAddToCart={() =>
                  product.variants.length === 1
                    ? addProductVariantToCart(variantId, product)
                    : openProductVariantsSelection(product)
                }
                subImageUrl={subImageUrl}
                {...variantItem}
              />
            </Grid>
          );
        })}
      </Grid>

      <SelectableVariantCartPopup
        selectedProduct={selectedProduct}
        onAddToCart={addProductVariantToCart}
      />
    </>
  );
};

export default ExpandableProductList;
