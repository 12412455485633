import { useCallback } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { SortBy, ProductSearchKey, ProductSearch, ProductStatus } from "src/types/product-filter";

const useSearchProducts = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback(
    (params: Partial<ProductSearch> = {}) => {
      const isCollectionChanged = "collection" in params;

      const newSearchParamsObj: Record<keyof ProductSearch, string> = {
        collection: params.collection || "",
        search: "",
        sortBy: SortBy.LATEST,
        status: ProductStatus.AVAILABLE,
        paging: "1",
      };

      const searchParamsObj: Record<keyof ProductSearch, string> = {
        collection: searchParams.get(ProductSearchKey.COLLECTION) || "",
        search: searchParams.get(ProductSearchKey.SEARCH) || "",
        sortBy: searchParams.get(ProductSearchKey.SORT_BY) || SortBy.LATEST,
        status: searchParams.get(ProductSearchKey.STATUS) || ProductStatus.AVAILABLE,
        paging: !(ProductSearchKey.PAGING in searchParams)
          ? "1"
          : searchParams.get(ProductSearchKey.PAGING) || "1",
        ...params,
      };

      navigate({
        pathname: "/products",
        search: `?${createSearchParams(
          isCollectionChanged ? newSearchParamsObj : searchParamsObj
        )}`,
      });
    },
    [navigate, searchParams]
  );
};

export default useSearchProducts;
