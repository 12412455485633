import { Box, useMediaQuery } from "@mui/material";

import { useParams } from "react-router-dom";
import useFetchProductDetail from "src/hooks/useFetchProductDetail";
import NotFoundPage from "src/pages/NotFoundPage/NotFoundPage";
import ImagePreviewScroll from "src/components/ImagePreviewScroll";
import ProductDetailDescription from "src/components/ProductDetailDescription";
import { HeightPX } from "src/constants";
import useMergeAllProductImages from "src/hooks/useMergeImagesProduct";
import ProductDetailLoading from "src/pages/ProductDetailPage/ProductDetailLoading";
import ImageListHorizontalScroll from "src/pages/ProductDetailPage/ImageListHorizontalScroll";

const height = `calc(100vh - ${HeightPX.NAV_BAR})`;

const ProductDetailBody = () => {
  const customViewSwitch = useMediaQuery("(max-width: 1200px)");
  const { productId } = useParams();

  const { productDetail, isFetching, error } = useFetchProductDetail({ id: productId });

  const images = useMergeAllProductImages(productDetail);

  if (error) {
    return <NotFoundPage backUrl="/products" />;
  }
  if (isFetching) {
    return <ProductDetailLoading />;
  }

  if (customViewSwitch) {
    return (
      <Box>
        <ImageListHorizontalScroll images={images} />
        <ProductDetailDescription productDetail={productDetail} />
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1, height }}>
          <ImagePreviewScroll images={images} />
        </Box>
        <Box sx={{ flex: 1, height, overflow: "auto" }}>
          <ProductDetailDescription productDetail={productDetail} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductDetailBody;
