import { CollectionResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/utils/service";

import { useQuery } from "@tanstack/react-query";

const useSignatures = () => {
  const { data: dataSignature, isFetching } = useQuery<QueryDataResponse<CollectionResponse>>({
    staleTime: Infinity,
    cacheTime: 0,
    queryKey: ["signature"],
    queryFn: () =>
      getData({
        path: "/collections/signature",
      }),
  });

  return {
    signatures: dataSignature?.items || [],
    isFetching,
  };
};

export default useSignatures;
