import { useQuery } from "@tanstack/react-query";
import { ProvinceResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/utils/service";

const useFetchProvinces = () => {
  const { data, isFetching } = useQuery<QueryDataResponse<ProvinceResponse, false>>({
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    queryKey: ["provinces"],
    queryFn: () =>
      getData({
        path: "/public/provinces",
        config: {
          params: {
            limit: -1,
            offset: 0,
            sort_by: "created_at",
            sort_dir: "desc",
          },
        },
      }),
  });

  return {
    provincesData: data ? data.items : [],
    isFetchingProvinces: isFetching,
  };
};

export default useFetchProvinces;
