import { Breadcrumbs, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ProductResponse } from "src/types/services";

const ProductDetailBreadcrumbs = ({ productDetail }: { productDetail: ProductResponse }) => {
  const navigate = useNavigate();

  const productCollectionId = productDetail.collection_ids[0];
  const collectionName = productDetail.product_collection_paths[0]?.find(
    (collectionPath) => collectionPath.id === productCollectionId
  )?.title;

  if (!productCollectionId || !collectionName) {
    return null;
  }

  const collectionBreadcrumbs = [
    {
      label: "TRANG CHỦ",
      to: "/",
    },
    {
      label: "SẢN PHẨM",
      to: "/products",
    },
  ];

  return (
    <Breadcrumbs sx={{ mb: 3, fontSize: "12px" }} separator={<NavigateNextIcon fontSize="small" />}>
      {collectionBreadcrumbs.map(({ label, to }) => (
        <Button key={label} color="inherit" onClick={() => navigate(to)} size="small">
          {label}
        </Button>
      ))}
      <Typography color="text.primary" fontSize={12}>
        {productDetail.name}
      </Typography>
    </Breadcrumbs>
  );
};

export default ProductDetailBreadcrumbs;
