import { Stack } from "@mui/material";
import MobileHeader from "src/components/MobileHeader";
import ProductDetailBody from "src/pages/ProductDetailPage/ProductDetailBody";

const ProductDetailPage = () => (
  <Stack sx={{ overflow: "auto" }}>
    <MobileHeader />
    <ProductDetailBody />
  </Stack>
);

export default ProductDetailPage;
