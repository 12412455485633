import { forwardRef } from "react";

import { NumericFormat, NumericFormatProps } from "react-number-format";

interface NumericFormatInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatInput = forwardRef<NumericFormatProps, NumericFormatInputProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        getInputRef={ref}
        allowNegative={false}
        thousandSeparator
        valueIsNumericString
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        {...other}
      />
    );
  }
);

export default NumericFormatInput;
