import { Box, Stack } from "@mui/material";
import MobileHeader from "src/components/MobileHeader";
import PostsList from "src/components/PostsList";
import { HeightPX } from "src/constants";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import useFetchPosts from "src/hooks/useFetchPosts";
import useFetchNextPageScrollBottom from "src/hooks/useFetchScrollBottom";

const EditorialsPage = () => {
  const { matchMobileView, matchMobileViewSmall } = useCustomMediaQuery();
  const queryPosts = useFetchPosts({ type: "EDITORIAL" });

  const handleOnScroll = useFetchNextPageScrollBottom({
    hasNextPage: queryPosts.hasNextPage,
    fetchNextPage: queryPosts.fetchNextPage,
  });

  return (
    <Stack>
      <MobileHeader hideAutoplayBrandingBigScreen />
      <Box
        height={`calc(100vh  ${matchMobileView ? `- ${HeightPX.MOBILE_HEADER}` : ""} - 0.5px)`} //should fix hard 0.5px
        overflow={"auto"}
        onScroll={handleOnScroll}>
        <PostsList
          queryResult={queryPosts}
          columns={matchMobileViewSmall ? 1 : 2}
          appliedCustomWith
        />
      </Box>
    </Stack>
  );
};

export default EditorialsPage;
