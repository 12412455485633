import PostItem from "src/components/PostsList/PostItem";
import { PostResponse } from "src/types/services";

const PostsColumn = ({
  posts,
  postItemWidth,
}: {
  posts: PostResponse[];
  postItemWidth?: number;
}) => {
  return (
    <>
      {posts.map((postItem) => (
        <PostItem key={postItem.id} postItem={postItem} postItemWidth={postItemWidth} />
      ))}
    </>
  );
};

export default PostsColumn;
