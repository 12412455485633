import { useMediaQuery, useTheme } from "@mui/material";

const useCustomMediaQuery = () => {
  const theme = useTheme();
  const matchViewLarge = useMediaQuery("(min-width: 1400px)");
  const matchMobileView = useMediaQuery("(max-width: 1400px)");
  const matchMobileViewSmall = useMediaQuery("(max-width: 600px)");
  const matchMobileViewExtraSmall = useMediaQuery("(max-width: 340px)");
  const matchMobileViewMedium = useMediaQuery(theme.breakpoints.down("md"));

  return {
    matchViewLarge,
    matchMobileView,
    matchMobileViewMedium,
    matchMobileViewSmall,
    matchMobileViewExtraSmall,
  };
};

export default useCustomMediaQuery;
