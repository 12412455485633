import { CartItem } from "src/redux/features/CartSlice";
import { ProductResponse, VariantResponse } from "src/types/services";
import { ProductVariantInfo } from "src/types/views";

export const getMappingProductVariantInfo = (
  productDetail: ProductResponse | undefined,
  index = 0
): ProductVariantInfo | null => {
  if (!productDetail || !productDetail.variants || !productDetail.variants.length) {
    return null;
  }

  const { variants, images, ...restProductDetail } = productDetail;
  const isOutOfStock = variants.every(({ stock_quantity }) => stock_quantity <= 0);
  const { attributes, retail_price, id, stock_quantity } = variants[index];
  const mainImage = images && images.length ? images[0].url : "";
  const subImage =
    images && images.length && images[1] ? images[1].url : mainImage ? mainImage : "";

  return {
    ...restProductDetail,
    imageUrl: mainImage,
    subImageUrl: subImage,
    isOutOfStock: isOutOfStock,
    variantId: id,
    variantPrice: retail_price,
    totalVariant: variants.length,
    stockQuantity: stock_quantity,
    attributes: attributes.map(({ name, value }) => ({
      attribute_name: name,
      attribute_value: value,
    })),
  };
};

export const getVariantsInfo = ({
  productName,
  variant,
}: {
  variant: VariantResponse;
  productName: string;
}) => {
  const { attributes, id: variantId, retail_price, image, stock_quantity } = variant;

  const attribute_values = attributes.map(({ name, value }) => ({
    attribute_name: name,
    attribute_value: value,
  }));

  const cartItem: CartItem = {
    product_name: productName,
    product_price: retail_price,
    quantity: 1,
    variant_id: variantId,
    imageUrl: image?.url ?? "",
    stockQuantity: stock_quantity,
    attribute_values,
  };

  return {
    ...cartItem,
    isOutOfStock: stock_quantity <= 0,
  };
};
