export enum ProductStatus {
  AVAILABLE = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
}

export enum SortBy {
  PRICE_ASC = "price_sort_dir-ASC",
  PRICE_DESC = "price_sort_dir-DESC",
  LATEST = "created_at-DESC",
}

export enum ProductSearchKey {
  STATUS = "status",
  SORT_BY = "sortBy",
  SEARCH = "search",
  COLLECTION = "collection",
  PAGING = "paging",
}

export interface ProductSearch {
  [ProductSearchKey.STATUS]: ProductStatus;
  [ProductSearchKey.SORT_BY]: SortBy;
  [ProductSearchKey.SEARCH]: string;
  [ProductSearchKey.COLLECTION]: string;
  [ProductSearchKey.PAGING]: string;
}
