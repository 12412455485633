import { Box, BoxProps, Typography } from "@mui/material";
import { HeightPX } from "src/constants";
import useFetchHomePageInfo from "src/hooks/useFetchHomePageInfo";
import { styled } from "styled-components";

const ScrollContainer = styled(Box)`
  color: white;
  overflow: hidden;
  background-color: black;
  height: ${HeightPX.NAV_BAR};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ScrollSection = styled(Box)`
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 40s linear infinite;
  -webkit-animation: my-animation 40s linear infinite;
  animation: my-animation 40s linear infinite;

  /* for Firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
`;

type BrandingContentAutoplaySectionProps = Pick<BoxProps, "sx">;
const BrandingContentAutoplaySection = ({ sx }: BrandingContentAutoplaySectionProps) => {
  const { brandingContent } = useFetchHomePageInfo();

  return (
    <ScrollContainer
      sx={{
        background: "black",
        color: "white",
        ...sx,
      }}>
      <ScrollSection>
        <Typography variant="body2" whiteSpace={"nowrap"}>
          {brandingContent}
        </Typography>
      </ScrollSection>
    </ScrollContainer>
  );
};

export default BrandingContentAutoplaySection;
