import { Box } from "@mui/material";
import { styled } from "styled-components";

export const MainContainer = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  background: #f7f7f7;
`;
