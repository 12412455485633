import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";

import { getNestedMenuByKey } from "src/utils/menu";
import useFetchMenus from "src/hooks/useFetchMenus";
import useSearchProducts from "src/hooks/useSearchProducts";

const CategoriesList = () => {
  const searchProducts = useSearchProducts();
  const { menusData, isFetchingMenus } = useFetchMenus();
  const leftMenuNestedData = getNestedMenuByKey("left-menu", menusData);

  const handleClickCategory = useCallback(
    (categoryId: string) => {
      searchProducts({ collection: categoryId });
    },
    [searchProducts]
  );

  const categoriesData = useMemo(() => {
    if (!leftMenuNestedData || !("sub_menus" in leftMenuNestedData)) {
      return [];
    }

    return leftMenuNestedData.sub_menus;
  }, [leftMenuNestedData]);

  if (isFetchingMenus) {
    return <CircularProgress />;
  }

  if (categoriesData.length === 0) {
    return <Typography fontSize={13}>No data category</Typography>;
  }

  return (
    <Box>
      {categoriesData.map(({ collection: { title }, sub_menus: subGroupMenus }, categoryIndex) => (
        <Stack key={`category-item-${categoryIndex}`}>
          <Typography fontSize={14} fontWeight={600}>
            {title}
          </Typography>

          {subGroupMenus.map((subGroupMenu, index) => (
            <Box key={`category-children-item-${index}`} pb={1.5}>
              {subGroupMenu.sub_menus.map(
                ({ collection: { title: subMenuTitle, id: subMenuId } }, indexChild) => (
                  <Box
                    width="fit-content"
                    sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
                    onClick={() => handleClickCategory(subMenuId)}
                    key={`category-name-${indexChild}`}>
                    <Typography fontSize={13}>{subMenuTitle}</Typography>
                  </Box>
                )
              )}
            </Box>
          ))}
        </Stack>
      ))}
    </Box>
  );
};

export default CategoriesList;
