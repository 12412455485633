import { Box, Stack, Typography } from "@mui/material";
import CartItemQuantityEditable from "src/components/CartItemQuantityEditable";
import { type CartItem } from "src/redux/features/CartSlice";
import { convertStringCurrency, reduceUrlImage } from "src/utils/helper";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
`;

const CartMiniItem = ({
  product_name,
  product_price,
  quantity,
  variant_id,
  imageUrl,
  attribute_values,
  isTheLastItem,
  stockQuantity,
}: CartItem & { isTheLastItem?: boolean }) => (
  <Box display="flex" borderBottom={!isTheLastItem ? `0.5px solid black` : undefined}>
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      overflow={"hidden"}
      flex={1}>
      <Image src={reduceUrlImage({ originUrl: imageUrl, width: 150 })} />
    </Box>

    <Stack gap={0.5} p={1} pl={2} overflow={"hidden"} flex={2}>
      <Typography variant="body2" fontWeight={500} noWrap>
        {product_name}
      </Typography>
      <Typography variant="body2">
        {attribute_values.map(({ attribute_value }) => attribute_value).join(" / ")}
      </Typography>
      <Typography fontFamily="monospace" variant="body2">
        {convertStringCurrency(product_price * quantity)}
      </Typography>
      <CartItemQuantityEditable
        variant_id={variant_id}
        quantity={quantity}
        stockQuantity={stockQuantity}
      />
    </Stack>
  </Box>
);

export default CartMiniItem;
