import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { IndexUI } from "src/constants";
import AddShoppingCartTwoToneIcon from "@mui/icons-material/AddShoppingCartTwoTone";
import { ProductResponse, VariantResponse } from "src/types/services";
import { useState } from "react";
import ProductVariantSelection from "src/components/ProductVariantSelection/ProductVariantSelection";
import useSelectDefaultVariant from "src/hooks/useSelectDefaultVariantId";

const SelectableVariantCartPopup = ({
  selectedProduct,
  onAddToCart,
}: {
  selectedProduct?: ProductResponse;
  onAddToCart: (variantId: string) => void;
}) => {
  const [selectedVariant, setSelectedVariant] = useState<VariantResponse>();

  useSelectDefaultVariant({
    productItem: selectedProduct,
    defaultVariantId: selectedVariant?.id,
    onSelect: (variant) => setSelectedVariant(variant),
  });

  if (!selectedProduct) {
    return null;
  }

  const handleCancel = () => {
    onAddToCart("");
  };

  const handleSelectVariant = (variant: VariantResponse) => {
    setSelectedVariant(variant);
  };

  return (
    <Dialog open={Boolean(selectedProduct)} fullWidth maxWidth="xs" sx={{ zIndex: IndexUI.POPUP }}>
      <Stack gap={2}>
        <Typography variant="button" p={3} pb={2} borderBottom={"0.5px solid black"}>
          VUI LÒNG CHỌN BIẾN THỂ CỦA SẢN PHẨM:
        </Typography>

        <Box px={3}>
          <ProductVariantSelection
            selectedVariantId={selectedVariant?.id}
            productDetail={selectedProduct}
            onSelectVariant={handleSelectVariant}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 3,
            pt: 2,
            borderTop: "0.5px solid black",
          }}>
          <Button color="inherit" onClick={handleCancel}>
            Quay lại
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "black", borderRadius: "30px" }}
            startIcon={<AddShoppingCartTwoToneIcon />}
            onClick={() => onAddToCart(selectedVariant?.id || "")}>
            Thêm vào giỏ hàng
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default SelectableVariantCartPopup;
