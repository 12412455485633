import { Box, Breadcrumbs, Typography } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

const ShippingInfoHeader = () => {
  const { matchMobileViewMedium } = useCustomMediaQuery();

  return (
    <Box
      sx={{
        borderBottom: "0.5px solid black",
        ...(matchMobileViewMedium ? { borderTop: "0.5px solid black", pt: 2 } : {}),
      }}>
      {matchMobileViewMedium ? null : (
        <Typography variant="h4" fontWeight="600" p={2}>
          VẠC
        </Typography>
      )}
      <Breadcrumbs
        sx={{ px: 2, pb: 2, fontSize: 12 }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        <Link
          color="inherit"
          to="/"
          rel="noreferrer"
          style={{
            color: "black",
            margin: 0,
          }}>
          Trang chủ
        </Link>
        <Typography color="text.primary" fontSize={12}>
          Giỏ hàng
        </Typography>
        <Typography color="text.primary" fontSize={12}>
          Thông tin giao hàng
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default ShippingInfoHeader;
