import { Box, Tooltip, Typography } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import RecruitmentComponent from "src/components/ContactInfo/RecruitmentComponent";
import ContactComponent from "./ContactComponent";
import DefaultComponent from "./DefaultComponent";
import InfoComponent from "./InfoComponent";
import SizeGuideComponent from "./SizeGuideComponent";
import SupportComponent from "./SupportComponent";
import ShippingAndPaymentComponent from "src/components/ContactInfo/ShippingAndPaymentComponent";
import OrderGuideComponent from "src/components/ContactInfo/OrderGuideComponent";

const InfoText = ({ content }: { content: string }) => (
  <Tooltip title={content} placement="left-start">
    <Typography
      sx={{ fontSize: 10, ":hover": { textDecoration: "underline" } }}
      fontWeight={600}
      noWrap>
      {content}
    </Typography>
  </Tooltip>
);

type ContactInfoProps = {
  setCurrentSection: React.Dispatch<React.SetStateAction<JSX.Element>>;
  defaultHomePage?: () => JSX.Element;
};

const ContactInfo = ({ setCurrentSection, defaultHomePage }: ContactInfoProps) => {
  const leftSideInfos = [
    {
      content: "TRANG CHỦ",
      component: defaultHomePage ?? DefaultComponent,
    },
    {
      content: "GIỚI THIỆU",
      component: InfoComponent,
    },
    {
      content: "HƯỚNG DẪN CHỌN SIZE",
      component: SizeGuideComponent,
    },
    {
      content: "HỖ TRỢ",
      component: SupportComponent,
    },
  ];

  const rightSideInfos = [
    {
      content: "HƯỚNG DẪN MUA HÀNG",
      component: OrderGuideComponent,
    },
    {
      content: "GIAO HÀNG VÀ THANH TOÁN",
      component: ShippingAndPaymentComponent,
    },
    {
      content: "TUYỂN DỤNG",
      component: RecruitmentComponent,
    },
    {
      content: "LIÊN HỆ",
      component: ContactComponent,
    },
  ];

  return (
    <Grid
      container
      columns={24}
      height={"74px"}
      sx={{
        "--Grid-borderWidth": "0.5px",
        borderTop: "var(--Grid-borderWidth) solid",
        "& > div": {
          borderRight: "var(--Grid-borderWidth) solid",
          borderBottom: "var(--Grid-borderWidth) solid",
        },
      }}>
      {[leftSideInfos, rightSideInfos].map((columnInfos, index) => (
        <Grid xs={12} px={1.5} py={0.5} key={index}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {columnInfos.map(({ content, component }, index) => (
              <Box
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => setCurrentSection(component)}>
                <InfoText content={content} />
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ContactInfo;
