import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = ({ backUrl = "/" }: { backUrl?: string }) => {
  const navigate = useNavigate();
  return (
    <Box
      height={"100vh"}
      flex={1}
      display="flex"
      textAlign="center"
      flexDirection="column"
      justifyContent="center">
      <Typography variant="h2" fontWeight={600}>
        Không tìm thấy kết quả
      </Typography>
      <Typography sx={{ mt: 2 }}>Chúng tôi không phát hiện sản phẩm bạn đang tìm kiếm</Typography>
      <Typography>Hãy thử lại với kết quả khác</Typography>

      <Box mt={2}>
        <Button
          variant="contained"
          sx={{ backgroundColor: "black", borderRadius: 0 }}
          onClick={() => navigate(backUrl)}>
          Quay lại
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
