import { useQuery } from "@tanstack/react-query";
import { VoucherResponse } from "src/types/services";
import { getData } from "src/utils/service";

const useVoucher = ({
  code,
  onSuccess,
  onError,
}: {
  code: string;
  onSuccess: (dataResponse: VoucherResponse) => void;
  onError: () => void;
}) => {
  const { isFetching, refetch, data, isRefetchError } = useQuery<VoucherResponse>({
    queryKey: ["vouchers-order"],
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 0,
    queryFn: () =>
      getData({
        path: "/vouchers",
        config: {
          params: {
            code,
          },
        },
      }),
    onSuccess,
    onError,
  });

  return {
    isVoucherChecking: isFetching,
    checkVoucher: refetch,
    voucher: !isRefetchError ? data : null,
  };
};

export default useVoucher;
