import { Box, Pagination } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { IndexUI } from "src/constants";
import useSearchProducts from "src/hooks/useSearchProducts";
import { ProductSearchKey } from "src/types/product-filter";

const ProductListPagination = ({ count }: { count: number }) => {
  const [searchParams] = useSearchParams();
  const searchProducts = useSearchProducts();

  if (count < 2) {
    return null;
  }

  return (
    <Box
      position={"absolute"}
      top={"100%"}
      display={"flex"}
      justifyContent={"center"}
      width={"100%"}
      py={1}
      borderTop={"0.5px solid black"}
      zIndex={IndexUI.STICKY_ELEMENT}
      bgcolor={"#f7f7f7"}>
      <Pagination
        count={count}
        size="small"
        page={Number(searchParams.get(ProductSearchKey.PAGING)) || 1}
        variant="outlined"
        color="primary"
        onChange={(_, page) => {
          if (page.toString() !== searchParams.get(ProductSearchKey.PAGING)) {
            searchProducts({ paging: page.toString() });
          }
        }}
      />
    </Box>
  );
};

export default ProductListPagination;
