import { Box, Typography, Link } from "@mui/material";

import RightSectionHeader from "src/layouts/MainRightSection/RightSectionHeader";
import { IndexUI } from "src/constants";

import { useNavigate } from "react-router-dom";
import ContactInfo from "src/components/ContactInfo";
import { useMemo, useState } from "react";
import DefaultComponent from "src/components/ContactInfo/DefaultComponent";

const MainRightSection = () => {
  const navigate = useNavigate();

  const [currentSection, setCurrentSection] = useState(DefaultComponent);
  const CurrentComponent = useMemo(() => currentSection, [currentSection]);

  const handleLogoClick = () => {
    navigate("/");
    setCurrentSection(DefaultComponent);
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        borderLeft: "0.5px solid black",
        height: "100vh",
      }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: IndexUI.STICKY_ELEMENT,
          borderBottom: "0.5px solid black",
        }}>
        <RightSectionHeader />
      </Box>

      <Link
        onClick={handleLogoClick}
        underline="hover"
        color="inherit"
        px={2.5}
        sx={{ cursor: "pointer", width: "50px" }}>
        <Typography variant="h4" fontWeight={500} sx={{ mt: 2 }}>
          VẠC&apos;s CLOSET
        </Typography>
      </Link>

      {CurrentComponent}

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          width: "100%",
          background: "#f7f7f7",
        }}>
        <ContactInfo setCurrentSection={setCurrentSection} />
      </Box>
    </Box>
  );
};

export default MainRightSection;
