import { MenuItem } from "src/types/menuItem";
import { MenuDataResponse } from "src/types/services";

export const transformFlatToNestedMenu = (
  menuItems: MenuDataResponse["menu_items"] = []
): Map<string, MenuItem> => {
  const itemMap = new Map<string, MenuItem>();

  for (const item of menuItems) {
    const newItem = { ...item, sub_menus: [] };
    itemMap.set(item.collection_id, newItem);
  }

  itemMap.forEach((value, _, map) => {
    const parentId = value.path[value.path.length - 2];
    const parent = map.get(parentId);
    if (parent) {
      parent.sub_menus.push(value);
    }
  });

  return itemMap;
};

export const getNestedMenuByKey = (key: string, menuItems: MenuDataResponse["menu_items"] = []) => {
  const nestedMenuDataMap = transformFlatToNestedMenu(menuItems);
  return nestedMenuDataMap.get(key);
};
