import { Box, Stack, Typography } from "@mui/material";
import GuideInformationSection from "src/components/ProductDetailDescription/GuideInformationSection";
import ProductDetailBreadcrumbs from "src/components/ProductDetailDescription/ProductDetailBreadcrumbs";
import SocialSupportSection from "src/components/ProductDetailDescription/SocialSupportSection";
import { ProductResponse } from "src/types/services";

const ProductDescriptionOutStock = ({ productDetail }: { productDetail: ProductResponse }) => {
  if (!productDetail) return null;

  return (
    <Stack
      sx={{
        padding: "5% 15%",
        justifyContent: "space-between",
      }}>
      <Box mt={2} mb={3}>
        <ProductDetailBreadcrumbs productDetail={productDetail} />

        <Typography variant="h5" fontWeight="600">
          {productDetail.name}
        </Typography>
        <Typography variant="subtitle1" fontWeight="500" mb={3}>
          Out Of Stock
        </Typography>

        <SocialSupportSection />

        <Typography
          fontSize={12}
          dangerouslySetInnerHTML={{ __html: productDetail?.description || "" }}
        />

        <GuideInformationSection />
      </Box>
    </Stack>
  );
};

export default ProductDescriptionOutStock;
