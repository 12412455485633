import { SyntheticEvent, useCallback } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Box, MenuItem, Select, SelectProps, Tab, Tabs, Typography } from "@mui/material";
import { SortBy, ProductSearchKey, ProductStatus } from "src/types/product-filter";
import useSearchProducts from "src/hooks/useSearchProducts";
import { useSearchParams } from "react-router-dom";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

type Selections = Record<"label" | "value", string>[];

const selections = {
  status: [
    { label: "Còn Hàng", value: ProductStatus.AVAILABLE },
    { label: "Hết Hàng", value: ProductStatus.OUT_OF_STOCK },
  ],
  sort: [
    { label: "Mới Nhất", value: SortBy.LATEST },
    { label: "Giá thấp đến cao", value: SortBy.PRICE_ASC },
    { label: "Giá cao đến thấp", value: SortBy.PRICE_DESC },
  ],
};

const FilterControl = ({
  value,
  onChange,
  options,
  isLargeView,
}: {
  value: string;
  onChange: (event: SyntheticEvent | SelectProps["onChange"], newValue?: string) => void;
  options: Selections;
  isLargeView: boolean;
}) => {
  return isLargeView ? (
    <Tabs value={value} onChange={(e, v) => onChange(e, v)}>
      {options.map(({ label, value }) => (
        <Tab sx={{ fontSize: 12 }} label={label} value={value} key={value} />
      ))}
    </Tabs>
  ) : (
    <Select
      variant="standard"
      value={value}
      onChange={(e) => onChange(e as SyntheticEvent, e.target.value)}
      sx={{ fontSize: 12 }}>
      {options.map(({ label, value }) => (
        <MenuItem value={value} key={value} sx={{ fontSize: 12 }}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

const FilterSection = () => {
  const { matchViewLarge } = useCustomMediaQuery();
  const searchProducts = useSearchProducts();
  const [searchParam] = useSearchParams();
  const handleSearch = useCallback(
    (key: string, value: string) => searchProducts({ [key]: value }),
    [searchProducts]
  );

  const currentStatus = searchParam.get(ProductSearchKey.STATUS) || ProductStatus.AVAILABLE;
  const currentSort = searchParam.get(ProductSearchKey.SORT_BY) || SortBy.LATEST;

  return (
    <>
      <FilterControl
        value={currentStatus}
        onChange={(e, v) => handleSearch("status", v as string)}
        options={selections.status}
        isLargeView={matchViewLarge}
      />
      <FilterControl
        value={currentSort}
        onChange={(e, v) => handleSearch("sortBy", v as string)}
        options={selections.sort}
        isLargeView={matchViewLarge}
      />
    </>
  );
};

const Filters = () => {
  const { matchMobileView } = useCustomMediaQuery();
  return (
    <Box
      sx={{
        px: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRight: "0.5px solid black",
      }}>
      <Box sx={{ display: "flex", gap: 1, justifyContent: "center", alignItems: "center" }}>
        <FilterAltOutlinedIcon fontSize="inherit" />
        <Typography variant="body2" fontSize={12}>
          {matchMobileView ? "" : "SẮP XẾP THEO"}
        </Typography>
      </Box>
      <FilterSection />
    </Box>
  );
};

export default Filters;
