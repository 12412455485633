import { Typography } from "@mui/material";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";
import RecruitmentImagePartTime from "src/assets/images/recruitment-detail.jpg";
import RecruitmentImageFullTime from "src/assets/images/recruitment-detail-fulltime.jpg";
import FullWithImage from "src/components/FullWithImage";

const RecruitmentComponent = () => (
  <ContactInfoContentLayout title="TUYỂN DỤNG" noPx>
    <FullWithImage src={RecruitmentImagePartTime} />
    <FullWithImage src={RecruitmentImageFullTime} />
    <Typography fontSize={12} px={3}>
      <b>HÌNH THỨC ỨNG TUYỂN:</b>
      <br />
      Nếu bạn muốn trở thành người nhà của Vạc, hãy cứ "mạnh dạng" gửi CV cho Vạc qua email
      <br />
      <b>vacclosetarchive@gmail.com</b>
      <br />
      Cửa hàng sẽ liên hệ với bạn khi có vị trí phù hợp nhé!
    </Typography>
  </ContactInfoContentLayout>
);

export default RecruitmentComponent;
