import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";

import { ProductResponse, VariantResponse } from "src/types/services";
import { convertStringCurrency } from "src/utils/helper";
import useSelectDefaultVariant from "src/hooks/useSelectDefaultVariantId";
import ProductVariantSelection from "src/components/ProductVariantSelection/ProductVariantSelection";
import useAddCartStorageByVariantId from "src/hooks/useAddCartStorageByVariantId";
import GuideInformationSection from "src/components/ProductDetailDescription/GuideInformationSection";
import SocialSupportSection from "src/components/ProductDetailDescription/SocialSupportSection";
import ProductDetailBreadcrumbs from "src/components/ProductDetailDescription/ProductDetailBreadcrumbs";
import ProductDescriptionOutStock from "src/components/ProductDetailDescription/ProductDescriptionOutStock";

type ProductDetailDescriptionProps = {
  productDetail?: ProductResponse;
};

const ProductDetailDescription = ({ productDetail }: ProductDetailDescriptionProps) => {
  const customViewSwitch = useMediaQuery("(max-width: 1200px)");

  const [selectedVariant, setSelectedVariant] = useState<VariantResponse>();

  const { onAddToCart } = useAddCartStorageByVariantId();

  useSelectDefaultVariant({
    productItem: productDetail,
    defaultVariantId: selectedVariant?.id,
    onSelect: (variant) => setSelectedVariant(variant),
  });

  if (!productDetail) {
    return null;
  }

  if (!selectedVariant) {
    return <ProductDescriptionOutStock productDetail={productDetail} />;
  }

  const handleAddToCart = () => {
    onAddToCart({ productItem: productDetail, variantId: selectedVariant.id || "" });
  };

  return (
    <Stack
      sx={{
        padding: customViewSwitch ? "0 5%" : "5% 15%",
        justifyContent: "space-between",
      }}>
      <Box mt={2} mb={3}>
        {customViewSwitch ? null : <ProductDetailBreadcrumbs productDetail={productDetail} />}

        <Typography variant="h5" fontWeight="600">
          {productDetail.name}
        </Typography>
        <Typography variant="subtitle1" fontWeight="500" mb={3}>
          {convertStringCurrency(selectedVariant.retail_price)}
        </Typography>

        {selectedVariant.attributes.map((variantAttribute) => (
          <Typography fontSize={12} key={`variant-attribute-${variantAttribute.id}`}>
            {variantAttribute.name} <u>{variantAttribute.value}</u>
          </Typography>
        ))}

        <Typography
          fontSize={12}
          dangerouslySetInnerHTML={{ __html: productDetail?.description || "" }}
        />

        <SocialSupportSection />

        <GuideInformationSection />

        <Stack gap={2} pt={2}>
          <ProductVariantSelection
            isHideOnlyOneItem
            selectedVariantId={selectedVariant.id}
            productDetail={productDetail}
            onSelectVariant={(variant) => setSelectedVariant(variant)}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ backgroundColor: "black", borderRadius: "30px", boxShadow: "none" }}
            onClick={handleAddToCart}>
            Thêm vào giỏ hàng
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ProductDetailDescription;
