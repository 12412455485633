import { Box } from "@mui/material";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";
import useFetchPosts from "src/hooks/useFetchPosts";
import DiscoveryPosts from "src/pages/DiscoveryPage/DiscoveryPosts";

const DiscoveryBody = () => {
  const { matchMobileView } = useCustomMediaQuery();
  const queryMixMatchPosts = useFetchPosts({ type: "MIX_MATCH" });
  const queryDailyUpdatePosts = useFetchPosts({ type: "DAILY_UPDATE" });

  return (
    <Box display={"flex"} textAlign={"center"}>
      <Box flex={1} borderRight={"0.5px solid black"}>
        <DiscoveryPosts queryPosts={queryMixMatchPosts} columns={1} />
      </Box>
      <Box flex={matchMobileView ? 1 : 3}>
        <DiscoveryPosts queryPosts={queryDailyUpdatePosts} columns={matchMobileView ? 1 : 3} />
      </Box>
    </Box>
  );
};

export default DiscoveryBody;
