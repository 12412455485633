import { Stack } from "@mui/material";
import DiscoveryBody from "src/pages/DiscoveryPage/DiscoveryBody";
import DiscoveryHeader from "src/pages/DiscoveryPage/DiscoveryHeader";

const DiscoveryPage = () => (
  <Stack>
    <DiscoveryHeader />
    <DiscoveryBody />
  </Stack>
);

export default DiscoveryPage;
