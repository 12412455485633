import { Box, Button } from "@mui/material";
import { ProductResponse, VariantResponse } from "src/types/services";

const ProductVariantSelection = ({
  productDetail,
  selectedVariantId,
  onSelectVariant,
  isHideOnlyOneItem = false,
}: {
  selectedVariantId?: string;
  productDetail: ProductResponse;
  onSelectVariant: (variant: VariantResponse) => void;
  isHideOnlyOneItem?: boolean;
}) => {
  const { variants } = productDetail;

  if (isHideOnlyOneItem && variants.length === 1) {
    return null;
  }

  return (
    <Box display={"flex"} gap={1.5} flexWrap={"wrap"}>
      {variants.map((productVariant) => {
        const { attributes, id: variantId, stock_quantity } = productVariant;
        const isOutOfStock = stock_quantity <= 0;

        return (
          <Button
            key={variantId}
            disabled={isOutOfStock}
            variant="contained"
            sx={{
              px: 2,
              cursor: "pointer",
              color: "#000",
              background: "white",
              borderRadius: "30px",
              ":hover": {
                color: "white",
                backgroundColor: "#000",
              },
              ...(selectedVariantId === variantId
                ? {
                    backgroundColor: "#000000",
                    color: "white",
                  }
                : {}),
            }}
            onClick={() => onSelectVariant(productVariant)}>
            {attributes.map(({ value }) => value).join(" / ")}
          </Button>
        );
      })}
    </Box>
  );
};

export default ProductVariantSelection;
