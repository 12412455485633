import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import cartReducer from "./features/CartSlice";
import DiscoveryDetailReducer from "./features/DiscoveryDetailSlice";

const rootReducer = combineReducers({
  cart: cartReducer,
  DiscoveryDetail: DiscoveryDetailReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
