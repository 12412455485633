import SizeGuideImage from "src/assets/images/size-guide.jpg";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";
import FullWithImage from "src/components/FullWithImage";

const SizeGuideComponent = () => (
  <ContactInfoContentLayout title="HƯỚNG DẪN CHỌN SIZE" noPx>
    <FullWithImage src={SizeGuideImage} />
  </ContactInfoContentLayout>
);

export default SizeGuideComponent;
