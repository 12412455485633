import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import CartMiniItem from "src/components/CartMiniItem";
import { IndexUI } from "src/constants";
import { itemsCartSelector, stateDrawerCart, toggleCart } from "src/redux/features/CartSlice";
import { useAppSelector } from "src/redux/store";
import { convertStringCurrency } from "src/utils/helper";
import useCustomMediaQuery from "src/hooks/useCustomMediaQuery";

const CART_HEADER_PX = "96px";
const CART_FOOTER_PX = "111px";

const CartDrawer = () => {
  const { matchViewLarge, matchMobileViewSmall } = useCustomMediaQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const carts = useAppSelector(itemsCartSelector);
  const state = useAppSelector(stateDrawerCart);

  const totalPrice = useMemo(
    () => carts.reduce((total, curCart) => (total += curCart.product_price * curCart.quantity), 0),
    [carts]
  );

  const onCloseCart = useCallback(() => dispatch(toggleCart(false)), [dispatch]);

  return (
    <Drawer open={state} anchor="right" sx={{ zIndex: IndexUI.POPUP }} onClose={onCloseCart}>
      <Stack
        width={matchViewLarge ? "20vw" : matchMobileViewSmall ? "100vw" : "450px"}
        pb={0}
        bgcolor={"#f7f7f7"}>
        <Box
          sx={{
            boxSizing: "border-box",
            height: CART_HEADER_PX,
            borderBottom: "0.5px solid black",
            p: 2,
          }}>
          <Box px={2} pb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
            <CloseIcon onClick={onCloseCart} fontSize="medium" />
            <Typography variant="h5" fontWeight="600" textAlign="right">
              GIỎ HÀNG
            </Typography>
          </Box>

          <Typography px={2}>{carts.length} sản phẩm</Typography>
        </Box>

        <Box
          sx={{ overflowY: "auto" }}
          height={`calc(100vh - ${CART_HEADER_PX} - ${CART_FOOTER_PX} )`}>
          {carts.map((cart, index) => (
            <CartMiniItem
              key={`cart-mini-item-${index}`}
              {...cart}
              isTheLastItem={index === carts.length - 1}
            />
          ))}
        </Box>

        <Box
          sx={{
            boxSizing: "border-box",
            height: CART_FOOTER_PX,
            borderTop: "0.5px solid black",
            p: 2,
          }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Tổng cộng:</Typography>
            <Typography fontSize={18} fontWeight={600}>
              {convertStringCurrency(totalPrice)}
            </Typography>
          </Box>

          <Box pt={2}>
            <Button
              variant="contained"
              fullWidth
              sx={{ backgroundColor: "#000000", borderRadius: "30px" }}
              onClick={() => {
                onCloseCart();
                navigate("/cart");
              }}>
              THANH TOÁN
            </Button>
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default CartDrawer;
