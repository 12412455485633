import { Box, Button, Typography } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const SocialSupportSection = () => {
  const socials = [
    {
      label: "FACEBOOK",
      link: "https://www.facebook.com/vac.closet",
      icon: <FacebookIcon />,
      background: "#1877F2",
    },
    {
      label: "INSTAGRAM",
      link: "https://www.instagram.com/vac.closet",
      icon: <InstagramIcon />,
      background:
        "radial-gradient(circle at 30% 170%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 95%)",
    },
  ];

  const onClickSocial = (link: string) => {
    window.open(link);
  };

  return (
    <>
      <Typography fontWeight="600" fontSize={12} mt={2}>
        Hỗ trợ thêm thông tin sản phẩm tại
      </Typography>
      <Box sx={{ display: "flex", gap: 2, my: 2 }}>
        {socials.map(({ label, link, background }) => (
          <Button
            fullWidth
            key={label}
            variant="contained"
            sx={{
              background,
              color: "white",
              borderRadius: "30px",
              boxShadow: "none",
              border: "0.5px solid transparent",
            }}
            onClick={() => onClickSocial(link)}>
            {label}
          </Button>
        ))}
      </Box>
    </>
  );
};

export default SocialSupportSection;
