import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { IndexUI } from "src/constants";

const ConfirmationDialog = ({
  show,
  onCancel,
  onConfirm,
}: {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 435,
        },
        zIndex: IndexUI.POPUP,
      }}
      maxWidth="xs"
      open={show}>
      <DialogTitle>Xác nhận</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          Thao tác trên có thể sẽ làm thay đổi dữ liệu, bạn có chắc chắn với thao tác vừa rồi
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          Hủy
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
