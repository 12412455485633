import { Box, Typography } from "@mui/material";
import ContactInfoContentLayout from "src/components/ContactInfo/ContactInfoContentLayout";

const SupportComponent = () => {
  const socials = [
    {
      label: "FACEBOOK",
      link: "https://www.facebook.com/vac.closet",
    },
    {
      label: "INSTAGRAM",
      link: "https://www.instagram.com/vac.closet",
    },
  ];

  return (
    <ContactInfoContentLayout title="HỖ TRỢ">
      <Typography fontSize={12}>
        Trong trường hợp cần hỗ trợ thêm thông tin,... bạn có thể liên hệ Vạc qua các kênh
      </Typography>
      <Box sx={{ display: "flex", gap: 3, px: 2 }}>
        {socials.map(({ label, link }) => (
          <a
            target="_blank"
            href={link}
            rel="noopener noreferrer"
            key={link}
            style={{ color: "black", textDecoration: "none" }}>
            <Typography variant="body2" fontWeight={500}>
              {label}
            </Typography>
          </a>
        ))}
      </Box>

      <Typography fontSize={12}>Hoặc HOTLINE</Typography>
      <Box px={2}>
        <Typography variant="body2" fontWeight={500}>
          0909 92 52 91
        </Typography>
      </Box>
    </ContactInfoContentLayout>
  );
};

export default SupportComponent;
