import { Box, Button } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCreateOrder from "src/hooks/useCreateOrder";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import { clearCart, itemsCartSelector } from "src/redux/features/CartSlice";
import { useAppSelector } from "src/redux/store";
import { OrderForm } from "src/types/forms";
import { transformOrder } from "src/utils/transform";

interface ShippingInfoFooterProps extends Pick<UseFormReturn<OrderForm>, "handleSubmit"> {
  handleOpenDialog: (dialogType: "failed" | "success") => void;
}

const ShippingInfoFooter = ({ handleSubmit, handleOpenDialog }: ShippingInfoFooterProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carts = useAppSelector(itemsCartSelector);
  const { mutate: onCreateOrder } = useCreateOrder({
    onSuccess: () => {
      handleOpenDialog("success");
      dispatch(clearCart());
    },
  });

  const showLoading = useShowOverlayLoading();

  const handleCompleteOrder = handleSubmit((data) => {
    showLoading(true);

    if (!carts.length) {
      handleOpenDialog("failed");
      showLoading(false);

      return;
    }

    const order = transformOrder({
      ...data,
      orderItems: [...carts.map((cartItem) => ({ ...cartItem, discounts: null }))],
    });

    onCreateOrder(order);
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderTop: "0.5px solid black",
        p: 2,
      }}>
      <Button onClick={() => navigate("/")} color="inherit">
        Tiếp tục mua sắm
      </Button>
      <Button
        variant="contained"
        sx={{ backgroundColor: "black", borderRadius: "30px" }}
        onClick={handleCompleteOrder}>
        Hoàn tất đơn hàng
      </Button>
    </Box>
  );
};

export default ShippingInfoFooter;
