import { Box, Stack, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { IndexUI } from "src/constants";
import CheckoutInfoDiscount from "src/layouts/CartPageContent/CheckoutInfo/CheckoutInfoDiscount";
import { OrderForm } from "src/types/forms";
import { convertStringCurrency } from "src/utils/helper";
import { getVoucherPrice } from "src/utils/order";

interface BillItemProps {
  label: string;
  price: number;
}
const BillItem = ({ label, price }: BillItemProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography>{label}</Typography>
      <Typography>{convertStringCurrency(price)}</Typography>
    </Box>
  );
};

const CheckoutInfoFooter = ({
  tempPrice,
  orderFormMethod,
}: {
  tempPrice: number;
  orderFormMethod: UseFormReturn<OrderForm>;
}) => {
  const { setValue, watch } = orderFormMethod;

  const [voucherField, shipmentPrices] = watch(["voucherField", "shipment.shipmentPrices"]);

  const voucherPrice = voucherField
    ? getVoucherPrice({ totalProductsPrice: tempPrice, voucher: voucherField }) * -1
    : 0;

  const billItems: BillItemProps[] = [
    {
      label: "Tạm tính",
      price: tempPrice,
    },
    {
      label: "Giảm giá",
      price: voucherPrice,
    },
    {
      label: "Phí vận chuyển",
      price: shipmentPrices,
    },
  ];

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        zIndex: IndexUI.STICKY_ELEMENT,
        background: "#f7f7f7",
      }}>
      <CheckoutInfoDiscount setValue={setValue} />

      <Stack
        p={2}
        gap={0.5}
        borderTop={"0.5px solid black"}
        height={"112px"}
        boxSizing={"border-box"}>
        {billItems.map((billItem, index) => (
          <BillItem key={index} {...billItem} />
        ))}
      </Stack>

      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "0.5px solid black",
          height: "66px",
          boxSizing: "border-box",
        }}>
        <Typography variant="h5">TỔNG CỘNG</Typography>
        <Typography fontWeight="700" variant="h5">
          {convertStringCurrency(tempPrice + shipmentPrices + voucherPrice)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CheckoutInfoFooter;
