import { useQuery } from "@tanstack/react-query";
import { PostResponse } from "src/types/services";
import { getData } from "src/utils/service";

const usePostDetail = ({
  id,
  onSuccess,
}: {
  id?: string;
  variantId?: string;
  onSuccess?: ((data: PostResponse) => void) | undefined;
}) => {
  const { data: postDetail } = useQuery<PostResponse>({
    refetchOnWindowFocus: false,
    queryKey: ["post-detail", { id }],
    queryFn: async () => await getData({ path: "/posts", slug: id }),
    enabled: Boolean(id),
    onSuccess,
  });

  return {
    postDetail,
  };
};

export default usePostDetail;
