import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import { CartItem, deleteItemCart, updateQuantityCart } from "src/redux/features/CartSlice";
import styled from "styled-components";

const QuantityButton = styled.button`
  background: none;
  border: none;
  width: 26px;
  height: 26px;
  cursor: pointer;
  &:hover:not(:disabled) {
    color: #00000080;
  }
`;

const CartItemQuantityEditable = ({
  quantity,
  variant_id,
  stockQuantity,
}: Pick<CartItem, "variant_id" | "quantity" | "stockQuantity">) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handleDeleteCartItem = () => {
    dispatch(deleteItemCart({ variant_id }));
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
  };
  const dispatch = useDispatch();

  const handleIncreaseQuantity = () => {
    dispatch(updateQuantityCart({ variant_id, type: "increase" }));
  };

  const handleDecreaseQuantity = () => {
    if (quantity === 1) {
      setShowConfirm(true);
    } else {
      dispatch(updateQuantityCart({ variant_id, type: "decrease" }));
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        width={"fit-content"}
        border={"0.5px solid black"}
        borderRadius={"30px"}>
        <QuantityButton disabled={quantity === 0} onClick={handleDecreaseQuantity}>
          -
        </QuantityButton>
        <Typography
          fontFamily="monospace"
          variant="caption"
          lineHeight={"100%"}
          px={0.5}
          sx={{ placeContent: "center" }}>
          {quantity}
        </Typography>
        <QuantityButton disabled={quantity >= stockQuantity} onClick={handleIncreaseQuantity}>
          +
        </QuantityButton>
      </Box>

      <ConfirmationDialog
        show={showConfirm}
        onCancel={handleCancelDelete}
        onConfirm={handleDeleteCartItem}
      />
    </>
  );
};

export default CartItemQuantityEditable;
