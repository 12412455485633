import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { UIEventHandler, useCallback } from "react";

interface UseFetchScrollBottomProps
  extends Pick<UseInfiniteQueryResult, "hasNextPage" | "fetchNextPage"> {}

const useFetchNextPageScrollBottom = ({
  hasNextPage,
  fetchNextPage,
}: UseFetchScrollBottomProps) => {
  return useCallback<UIEventHandler<HTMLElement>>(
    (e) => {
      const elementTarget = e.target;
      if (elementTarget) {
        const { scrollHeight, scrollTop, clientHeight } = elementTarget as HTMLElement;
        const isScrollToEnd = scrollHeight - scrollTop - clientHeight < 50;

        if (isScrollToEnd && hasNextPage) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage]
  );
};

export default useFetchNextPageScrollBottom;
