import { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box } from "@mui/material";
import { IndexUI } from "src/constants";
import useSearchProducts from "src/hooks/useSearchProducts";
import styled from "styled-components";
import { ProductSearchKey } from "src/types/product-filter";
import { useSearchParams } from "react-router-dom";

const BorderlessInput = styled("input")`
  background: transparent;
  border: 0;
  border-style: none;
  border-color: transparent;
  outline: none;
  outline-offset: 0;
  box-shadow: none;
  font-size: 11px;
  width: 100%;
`;

const SearchBar = ({
  onFocus,
  onOutFocus,
  updateKeyword,
}: {
  onFocus?: () => void;
  onOutFocus?: () => void;
  updateKeyword?: (value: string) => void;
}) => {
  const searchProducts = useSearchProducts();
  let [searchParams] = useSearchParams();

  const productSearch = searchParams.get(ProductSearchKey.SEARCH);
  const [keyword, setKeyword] = useState<string>(productSearch || "");

  useEffect(() => {
    if (productSearch !== keyword) {
      setKeyword(productSearch || "");
    }
  }, [productSearch]);

  const handleChangePriceFilter = () => {
    searchProducts({ search: keyword });
    onOutFocus && onOutFocus();
  };

  return (
    <Box
      sx={{
        pl: 2.5,
        pr: 1,
        gap: 1,
        display: "flex",
        alignItems: "center",
        zIndex: IndexUI.STICKY_ELEMENT,
        height: "100%",
      }}>
      <SearchOutlinedIcon sx={{ fontSize: 15 }} />
      <BorderlessInput
        value={keyword}
        type="text"
        autoComplete="off"
        placeholder="TÌM KIẾM"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleChangePriceFilter();
          }
        }}
        onChange={(e) => {
          setKeyword(e.target.value);
          updateKeyword && updateKeyword(e.target.value);
        }}
        onFocus={onFocus}
      />
    </Box>
  );
};

export default SearchBar;
